import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../WeddingEntertainment/WeddingEntertainment.css";
import Card from "react-bootstrap/Card";

import CouplegrandEntryIcon from '../../images/couple-grand-entry-icon.png';
import BaraatEntertainment from '../../images/baraat-entertainment-icon.png';
import BaraatProcession from '../../images/baraa-procession-icon.png';
import MusicDanceIcon from '../../images/Music-Dance-icon.png';
import Spectacularentertainment from '../../images/spectacular-entertainment-acts.png';
import AnchorsComedians from '../../images/Anchors-ComediansIcon.png';
import StageLightingIcon from '../../images/Stage-LightingIcon.png';
import weddingEntertainment from '../../images/wedding-entertainment.png';
import Receptionentertainment from '../../images/reception-entertainment.png';
import engaginggamestalls from '../../images/engaging-game-stalls.png';
import uniqueentertainmentcounters from '../../images/unique-entertainment-counters.png';
import FunFoodStalls from '../../images/Fun-Food-Stalls.png';
import GrandCoupleEntry from "../../images/Grand-Couple-Entry.jpg";
import BharatEntertainmentIMG from '../../images/Baraat-Entertainment.jpg';
import BaraaatProcession from '../../images/Baraaat-Procession.jpg';
import MusicDance from '../../images/Music-Dance.jpg';
import SpectacularentertainmentImg from '../../images/spectacular-entertainment-Img.jpg';
import AnchorsComediansImg from '../../images/Anchors-Comedians-Img.jpg';
import StageLightingImg from '../../images/Stage-LightingImg.jpg';
import WeddingEntertainmentImg from '../../images/wedding-entertainment-Img.jpg';
import ReceptionentertainmentImg from '../../images/reception-entertainment-img.jpg';
import EngagingGameStallsImg from '../../images/engaging-game-stalls-img.jpg';
import UniqueEntertainmentCountersImg from '../../images/Unique-Entertainment-Counters.jpg';
import FunFoodStallsImg from '../../images/FunFoodStallImg.jpg';


const WeddingEntertainment = () => {
  
  return (
    <React.Fragment>
      <section className="inner_banner">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="position-absolute top-50 start-50 translate-middle ">
                <h2 className="text-uppercase">Wedding Entertainment</h2>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="hlello mb-4 mb-lg-5 d-flex flex-column justify-content-center align-items-center">
                <h2 className="text-center">
                  Nobody Does Wedding Entertainment Better Than Us
                </h2>
                <p className="text-center">
                  For the families and friends of both the groom and the bride,
                  Sangeet & Mehendi are the two most fun parts of the wedding.
                  We add an extra layer of fun and entertainment to these
                  functions, ensuring that your guests participate and enjoy
                  fully.
                </p>
                <p className="text-center">
                  We also conceptualize and execute a plethora of options for
                  your grand couple entry, wedding & reception entertainment.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col lg={12}>
              <ul className="servicesinsidenew">
                <li>
                  <div className="flip-container-main">
                    <div className="flip-container">
                      <div className="flipper">
                        <div className="front">
                          <div className="front-icon">
                            <img src={CouplegrandEntryIcon} alt="CouplegrandEntryIcon" />
                          </div>
                          <span className="name">Couple Grand Entry</span>
                        </div>
                        <div className="back">
                          <div className="border334">
                            <p>
                              Behind a well-executed grand entry is smart
                              planning. We conceptualize your style of entry
                              with necessary audio visual support, sound and
                              light design, SFX effects as per the venue and
                              budget to give you the most memorable entry.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hoverimage">
                      <img src={GrandCoupleEntry} alt="" />
                    </div>
                  </div>

                  <div className="flip-container-main">
                    <div className="flip-container">
                      <div className="flipper">
                        <div className="front">
                          <div className="front-icon">
                            <img src={BaraatEntertainment} alt="icon01" />
                          </div>
                          <span className="name">Baraat Entertainment </span>
                        </div>
                        <div className="back">
                          <div className="border334">
                            <ul>
                              <li>Nashik Dhol</li>
                              <li>Puneri Band </li>
                              <li>Brass Band </li>
                              <li>Punjabi Dhol with dancers </li>
                              <li>Variety of folk dancers</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hoverimage">
                      <img src={BharatEntertainmentIMG} alt="" />
                    </div>
                  </div>
                </li>

                <li>
                  <div className="flip-container-main">
                    <div className="hoverimage">
                      <img src={BaraaatProcession} alt="" />
                    </div>
                    <div className="flip-container">
                      <div className="flipper">
                        <div className="front">
                          <div className="front-icon">
                            <img src={BaraatProcession} alt="icon01" />
                          </div>
                          <span className="name">Baraat Procession</span>
                        </div>
                        <div className="back">
                          <div className="border334">
                            <ul>
                              <li>Vintage Car </li>
                             <li>Ghodi</li>
                              <li>Doli </li>
                              <li>Buggy</li>
                              <li>Safa </li>
                              <li>Swagat Mala </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flip-container-main">
                    <div className="hoverimage">
                      <img src={MusicDance} alt="" />
                    </div>
                    <div className="flip-container">
                      <div className="flipper">
                        <div className="front">
                          <div className="front-icon">
                            <img src={MusicDanceIcon} alt="MusicDanceIcon" />
                          </div>
                          <span className="name">Music &amp; Dance</span>
                        </div>
                        <div className="back">
                          <div className="border334">
                            <ul>
                              <li>Live band, Unplugged band </li>
                              <li>Celebrity Playback singers </li>
                              <li>Reality show singers </li>
                              <li>Popular stage performers </li>
                              <li>Maata ki Chowki </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="flip-container-main">
                    <div className="flip-container">
                      <div className="flipper">
                        <div className="front">
                          <div className="front-icon">
                            <img src={Spectacularentertainment} alt="icon01" />
                          </div>
                          <span className="name">
                          Spectacular Entertainment Acts
                          </span>
                        </div>
                        <div className="back">
                          <div className="border334">
                            <p>
                              Foreign Artists/ performers: Celebrity DJs,
                              Musicians, dancers, entertainers, welcome girls,
                              Bartenders, Live acts, Bartenders with fire acts,
                              Customised Bar set-ups, A wide range of Photobooth
                              set-up options
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hoverimage">
                      <img src={SpectacularentertainmentImg} alt="" />
                    </div>
                  </div>

                  <div className="flip-container-main">
                    <div className="flip-container">
                      <div className="flipper">
                        <div className="front">
                          <div className="front-icon">
                            <img src={AnchorsComedians} alt="AnchorsComedians" />
                          </div>
                          <span className="name">Anchors & Comedians </span>
                        </div>
                        <div className="back">
                          <div className="border334">
                            <ul>
                              <li>Anchor, hosts</li>
                              <li>Game-hosts</li>
                              <li>Stand up comedians</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hoverimage">
                      <img src={AnchorsComediansImg} alt="" />
                    </div>
                  </div>
                </li>

                <li>
                  <div className="flip-container-main">
                    <div className="hoverimage">
                      <img src={StageLightingImg} alt="" />
                    </div>
                    <div className="flip-container">
                      <div className="flipper">
                        <div className="front">
                          <div className="front-icon">
                            <img src={StageLightingIcon} alt="StageLightingIcon" />
                          </div>
                          <span className="name">Stage Lighting </span>
                        </div>
                        <div className="back">
                          <div className="border334">
                            <ul>
                              <li>Designer LED screens with dance floors</li>
                              <li>Designer lights with Truss</li>
                              <li>
                                Laser lights, SFX effects, Laser shows, LED
                                mapping
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flip-container-main">
                    <div className="hoverimage">
                      <img src={WeddingEntertainmentImg} alt="WeddingEntertainmentImg" />
                    </div>
                    <div className="flip-container">
                      <div className="flipper">
                        <div className="front">
                          <div className="front-icon">
                            <img src={weddingEntertainment} alt="icon01" />
                          </div>
                          <span className="name">Wedding Entertainment </span>
                        </div>
                        <div className="back">
                          <div className="border334">
                            <ul>
                              <li>Wedding Vedic chants</li>
                              <li>
                                Traditional Lagna geet - Gujarati, Marwari
                              </li>
                              <li>Indian Instrumental Band </li>
                              <li>Bridal Doli, Chattar</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="flip-container-main">
                    <div className="flip-container">
                      <div className="flipper">
                        <div className="front">
                          <div className="front-icon">
                            <img src={Receptionentertainment} alt="icon01" />
                          </div>
                          <span className="name">Reception Entertainment </span>
                        </div>
                        <div className="back">
                          <div className="border334">
                            <ul>
                              <li> Instrumental Band Indian / Western </li>
                              <li> Unplugged Band Live band</li>
                              <li>International artists /performers </li>
                              <li> Variety of Photo booth options</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hoverimage">
                      <img src={ReceptionentertainmentImg} alt="" />
                    </div>
                  </div>

                  <div className="flip-container-main">
                    <div className="flip-container">
                      <div className="flipper">
                        <div className="front">
                          <div className="front-icon">
                            <img src={engaginggamestalls} alt="icon01" />
                          </div>
                          <span className="name">Engaging Game Stalls </span>
                        </div>
                        <div className="back">
                         <div className="border334">
                            <div>
                            <ul>
                           <li>	Ring Toss</li>
                           <li>	Balloon Darts</li>
                           <li>	Fishing Game</li>
                           <li>	Shooting Gallery</li>
                           <li>	Wheel of Fortune</li>
                           <li>Photo Booth</li>
                           <li>	Virtual Reality Experience</li>
                           <li>Choose from an array of many more options!</li>
                            </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hoverimage">
                      <img src={EngagingGameStallsImg} alt="" />
                    </div>
                  </div>
                </li>

                <li>
                  <div className="flip-container-main">
                    <div className="hoverimage">
                      <img src={UniqueEntertainmentCountersImg} alt="" />
                    </div>
                    <div className="flip-container">
                      <div className="flipper">
                        <div className="front">
                          <div className="front-icon">
                            <img src={uniqueentertainmentcounters} alt="uniqueentertainmentcounters" />
                          </div>
                          <span className="name">
                            Unique Entertainment Counters
                          </span>
                        </div>
                        <div className="back">
                        <div className="border334">
                            <div>
                            <ul>
                           <li>Signature Game Show - Bollywood Badshah</li> 
                           <li> Fruit Toast </li> 
                           <li> Mentalist</li> 
                           <li> Tasseography </li> 
                           <li>  Walking Magician </li> 
                           <li>  Live Caricature </li> 
                           <li>  Henna Artist and many more… </li> 
                            </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flip-container-main">
                    <div className="hoverimage">
                      <img src={FunFoodStallsImg} alt="" />
                    </div>
                    <div className="flip-container">
                      <div className="flipper">
                        <div className="front">
                          <div className="front-icon">
                            <img src={FunFoodStalls} alt="icon01" />
                          </div>
                          <span className="name">Fun Food Stalls </span>
                        </div>
                        <div className="back">
                          <div className="border334">
                          <div>
                            <ul>
                          <li>Gourmet Popcorn</li> 
                          <li>Candy Floss</li> 
                          <li>90’s Food Counter</li> 
                          <li> Chattar Pattar</li> 
                          <li> Turkish Ice cream </li> 
 
                            </ul>
                            </div>

                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>


<section>
 <Container>
<Row className="equal-height-row">

   <Col className="equal-height-col" md={12} lg={4} >
    <Card className="mb-4 text-center rounded-0">
      <Card.Body>
      <div className="bg-white p-3 rounded-3 min-height">
          <Card.Title>
          We Have fabulous personal connect with the best performers in the industry.</Card.Title>

        </div>
      </Card.Body>
    </Card>
    </Col>


    <Col className="equal-height-col" md={12} lg={4}>
        <Card className="mb-4 text-center ">
      <Card.Body>
      <div className="bg-white p-3 rounded-3 min-height">
          <Card.Title>
          We deliver what we promise. 
        </Card.Title>

        </div>
      </Card.Body>
    </Card>
      </Col>



      <Col className="equal-height-col" md={12} lg={4}>
    <Card className="mb-4 text-center">
      <Card.Body>
      <div className="bg-white p-3 rounded-3 min-height">
          <Card.Title>
          We plan your wedding entertainment uniquely. 
         </Card.Title>

        </div>
      </Card.Body>
    </Card>
      </Col>




</Row>
 </Container>
</section>













    </React.Fragment>
  );
};

export default WeddingEntertainment;
