import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import WhyTab from './WhyTab';

const WhyUs = () => {
  return (
    <React.Fragment>
  <section className="inner_banner">
  <Container>
      <Row>
        <Col lg={12}>


             <div className="position-absolute top-50 start-50 translate-middle">
                <h4 className="d-block text-center">Wedding Planning</h4>
                <h2 className="text-uppercase text-center">Why Us</h2>

              </div>

          </Col></Row>
          </Container> 
    </section>




            <section className=" ">
        <Container className='pitch_bg'>
          <Row>
            <Col lg="12 ">
              <div className="py-4 ps-3">
                <h2 className='text-center'>We Tell Your Wedding Story By Planning</h2>
              <p className='text-center'>
              We are essentially story-tellers of important events. Our medium of storytelling is through event planning. That's why we call ourselves Wedding Stories because that's what we create - stories that will live on for years after the wedding. We plan your wedding and important events in a way that they become tales of magic and grandeur.


              </p>
            
            </div></Col>
            </Row>
            </Container>
            </section>

            <section>
              <WhyTab/>
              
      </section>







    </React.Fragment>

  )
}









export default WhyUs
