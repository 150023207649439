import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaFacebookF, FaLinkedin } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';
import { FaWhatsapp } from 'react-icons/fa';
import { FaPinterest } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';



import '../Header/Header.css';


const Header = () => {
  return (
    <>
            <Container fluid className='bg-white px-4 px-lg-4'>
        <Row>
          <Col lg="12">
            <div className="d-flex flex-column flex-lg-row  justify-content-center justify-content-between py-2 small text-secondary text-center">
              <div> <FaEnvelope /> hello@weddingstories.co.in  | <FaWhatsapp /> +91 9833743015</div>
              <div className='social_top_icon'>
                <a href="https://www.youtube.com/@weddingstoriesyt" target='_blank' rel='noreferrer' className='me-1'><FaYoutube /></a>
                <a href="https://www.facebook.com/weddingstorieseventsandentertainment"  target='_blank' rel='noreferrer'><FaFacebookF /></a>
                <a href="https://www.instagram.com/weddingstoriesig" className='me-1'  target='_blank' rel='noreferrer'> <FaInstagram /></a>
                <a href="https://in.pinterest.com/weddingstories"  target='_blank' rel='noreferrer'> <FaPinterest /></a>
                <a href="https://www.linkedin.com/company/wedding-stories-events-entertainment"  target='_blank' rel='noreferrer'> <FaLinkedin /></a>

               
              </div>

            </div>

          </Col>

        </Row>
      </Container>

    </>
  )
}

export default Header
