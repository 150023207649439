import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Career.css'

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2'


const Career = () => {


  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "99da9e3b-ebf2-4f56-bf52-f1e1cdb05ebb");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: json
    }).then((res) => res.json());

    if (res.success) {
      Swal.fire({
        title: "Sucess!",
        text: "Message sent successfully!",
        icon: "success",
     
      });
      event.target.reset();

    }
  };



  return (
    <React.Fragment>
        <section className="inner_banner">
  <Container>
      <Row>
        <Col lg={12}>

        <div className="position-absolute top-50 start-50 translate-middle ">
            <h2 className="text-uppercase">Career</h2>
             </div>
          </Col></Row>
          </Container> 
    </section>


    <Container>
<Row>
<Col md lg={12}>
<div className=''>
  <p className='text-center'>
  We're always looking for passionate &amp; dynamic events or travel professionals <br/> to enhance our team and provide exceptional value to our clients.
  </p>
  <h1 className='text-center'>EVENT MANAGEMENT JOBS &amp; CAREERS</h1>

</div>


        <div className='careerBox p-4 mt-4'>
          <h4 className='mb-2'>Event Managers</h4>
      <p>
      We hire professionals with the capability to multi-task - source and service new business, conceptualise winning solutions, and produce awesome events.
      </p>

      <p>
      We don't entertain applicants applying for a single function, viz., Business Development or Client Servicing or Operations. 
      </p>


      <p>University or College degree and minimum 5 yrs. in a similar job function (relevant experience)</p>

      <h6>Key Responsibilities:</h6>
      <ol>
      <li> Source and service new business</li>
      <li> Conceptualise winning solutions, and</li>
      <li> To ensure smooth &amp; flawless project execution.</li>
      </ol>


      <h6>Essential skills:</h6>
      <ol>

      <li>  Conversant with the Event and MICE industry,</li>
      <li> Generating New Business Leads,</li>
      <li>Client Servicing &amp; Project Management,</li>
      <li> Creating proposals and Budgeting,</li>
      <li>Venue sourcing,</li>
      <li> Vendor and Artist Management,</li>
      <li>MS Office,</li>
      <li> Excellent command over English - written &amp; spoken</li>
      </ol>
        </div>






</Col>
</Row>
</Container>

   
    <Container>
      <Row>
        <Col lg={12} className='mb-4'>

        <div className='mt-5'>
  <p className='text-center'> 
  Fill out the form below and if your application is shortlisted,<br/>our HR team will get in touch to set up an interview. </p>
  <h1 className='text-center'>APPLY ONLINE</h1>

</div>


    <Form onSubmit={onSubmit} >

<Form.Group className="mb-3" controlId="formBasicFullName">
<Form.Label  className='fw-bold'>Name<span className='text-danger'>*</span> </Form.Label>
<Form.Control name='name' type="text" placeholder="Enter full name"  required/>
</Form.Group>



<Form.Group className="mb-3" controlId="formBasicEmail" >
<Form.Label  className='fw-bold'>Email <span className='text-danger'>*</span></Form.Label>
<Form.Control name='email' type="email" placeholder="Enter email"  required/>
</Form.Group>


<Form.Group className="mb-3" controlId="formBasicMobile" >
<Form.Label  className='fw-bold'>Mobile no.<span className='text-danger'>*</span></Form.Label>
<Form.Control name='mobile' type="text" placeholder="Enter mobile no."  required/>
</Form.Group>



<Form.Group className="mb-3" controlId="formBasicPosition" >
<Form.Label  className='fw-bold'>Position<span className='text-danger'>*</span></Form.Label>

<Form.Select name='position'>
<option>Please choose an option</option>
<option>Event Manager</option>
<option>Intership</option>
<option>Other</option>
</Form.Select>

</Form.Group>



<Form.Group className="mb-3" controlId="formBasicMessage" >
        <Form.Label  className='fw-bold'>Message</Form.Label>
        <Form.Control  name='message'  as="textarea" rows={3} placeholder="Enter your message"/>
      </Form.Group>



      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label className='fw-bold'>Upload your CV (.pdf, .doc, .docx):</Form.Label>
        <Form.Control type="file" name="file"/>
      </Form.Group>





<Button type="submit" className='btn viewmore_btn  '>
Send Message
</Button>




</Form>
   
</Col></Row></Container>
    </React.Fragment>
  )
}

export default Career
