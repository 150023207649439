import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../About/About.css";

import AboutIcon01 from "../../images/about-icon-01.jpg";
import AboutIcon03 from "../../images/about-icon-03.jpg";
import weddingEnt from "../../images/about-wedding-entertainment-icon.jpg";
import AboutIcon04 from "../../images/wedding-theme.jpg";
import AboutIcon05 from "../../images/about-icon-05.jpg";
import we_icon from "../../images/about-icon-02.jpg";
import mw_icon from "../../images/Marquee-Weddings-icon.jpg";
import customize_budget_icon from "../../images/customize-budget-icon.jpg";






import { BiSolidDownArrow } from "react-icons/bi";

const AccordianTab = () => {
  return (
    <>
      <section className="custom_accordian mb-3">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={6}>
              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon01}
                    alt="AboutIcon01"
                    className="img-fluid"
                  />
                </Col>

                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">
                          Every Indian tradition under the sun
                        </h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                            We understand various hues of Indian culture. Our
                            wedding palette of 22 years is full of dreamy
                            weddings of all kinds. We want you to live each
                            custom of wedding with glee. Be it Pithi Dastoor,
                            Dwar Chakai, Mameru, Saptapadi, Pakki Misri-Kacchi
                            Misri, Chooda, Sangeet, Phera, Maayra, Haldi,
                            Mehendi or Arsi Musaf - we ensure your guests have
                            fun during every Indian custom.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>


              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon03}
                    alt="AboutIcon01"
                    className="img-fluid"
                  />
                </Col>

                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Destination Wedding
                        </h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          If you dream of a destination wedding anywhere around the world, look no further. Through our professionalism, phenomenal planning and our wholehearted dedication, we have built an enviable reputation for planning the most grand and elegant weddings since the last 22 years. We will oversee every detail. When you hire us, we are available for you 24 hours during your wedding.
                         </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon04}
                    alt="AboutIcon01"
                    className="img-fluid"
                  />
                </Col>

                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">
                        Wedding Themes
                        </h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          We are experts in organizing and planning themed weddings. We have worked on various wedding themes such as Peshwai, Under the Stars, Pichwai, White Wedding, Bollywood, Vintage, Rustic, Arabic, Moroccan, Royal Rajasthani, Kashmiri Style,Bohemian or specific on-demand themes such as The Great Gatsby, Flamingo, Sparkling mirror etc.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>


              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon05}
                    alt="AboutIcon01"
                    className="img-fluid"
                  />
                </Col>

                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">
                        You have our exclusive attention
                        </h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          We never forsake you for someone else. When you hire us, our entire team will work only for you and on your wedding. Nothing else in the world matters to us more than your wedding. We are available for you 24 hours during the wedding.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

            </Col>

            <Col md={6}>
              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={we_icon}
                    alt="we_icon"
                    className="img-fluid"
                  />
                </Col>
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Personalised Wedding Planning</h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content">
                        <div className="mt-2">
                          <hr />
                          <p>
                            You. Who are you deep down? What do you like? What’s
                            your favorite flower? What’s your pet peeve? It is
                            YOUR wedding, and that’s all that matters to us. Our
                            team truly gets to know your family to understand
                            your taste, your preferences. We study your
                            personality so all your guests go home with a little
                            bit of you after they attend your wedding.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>



              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={weddingEnt}
                    alt="AboutIcon02"
                    className="img-fluid"
                  />
                </Col>
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Wedding Entertainment</h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          We strongly believe that nobody does wedding entertainment better than us. As founders we have strong artistic leanings and hence it's only natural that our blood pumps to give your guests WOW entertainment. We conceptualize, arrange and execute a plethora of entertainment options apart from wedding planning, managing and coordinating.
                         </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>


              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={mw_icon}
                    alt="mw_icon"
                    className="img-fluid"
                  />
                </Col>
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Marquee Weddings</h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          Wedding Stories is a specialist in planning private marquee weddings. Now that more people are preferring to have private personalised weddings in smaller spaces, we couldn’t be happier. We thoughtfully plan and execute marquee weddings inside gardens, lawns, farm-houses, barren fields or open-air spaces. We create an overwhelming sense of fun around whatever surroundings /resources we are given.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={customize_budget_icon}
                    alt="AboutIcon02"
                    className="img-fluid"
                  />
                </Col>
             
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">We customize as per your budget</h5>
                        <BiSolidDownArrow />
                      </div>
                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr/>
                          <p>
                          We can create magic out of anything. A Five-star hotel, a palace in Jaipur or a beach in Goa, we can source venues and transform it into a 
                          stunning wedding destination. Be it getting vendors on board or working with your appointed vendors, 
                          we can make any wedding look like a grand luxury wedding, regardless of budget.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

              </Row>

















            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AccordianTab;
