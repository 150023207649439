import React from 'react'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import AboutIcon01 from "../../images/why-us-01.jpg";
import AboutIcon02 from "../../images/why-us-02.jpg";
import AboutIcon03 from "../../images/why-us-03.jpg";
import AboutIcon04 from "../../images/why-us-04.jpg";
import AboutIcon05 from "../../images/why-us-05.jpg";
import AboutIcon06 from "../../images/why-us-06.jpg";
import AboutIcon07 from "../../images/why-us-07.jpg";
import AboutIcon08 from "../../images/why-us-08.jpg";


import { BiSolidDownArrow } from "react-icons/bi";



const WhyTab = () => {
  return (
    <>
           <section className="py-4 custom_accordian">
        <Container>
          <Row className="justify-content-md-center">
{/* 
          <Col md={12} className='mb-3 text-center'>
                <h2>Why Us</h2>

          </Col> */}


            <Col md={6}>

              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon01}
                    alt="AboutIcon01"
                    className="img-fluid"
                  />
                </Col>

                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">
                        Get Value for the Money you Spend
                        </h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          Our goal is to create wows in your wedding vows by planning something beautiful and grand which does not necessarily mean that you spend a lot of money. Our focus is to help you, guide you to spend your money wisely and to get the best deals and deliverables for your wedding.


                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon02}
                    alt="AboutIcon01"
                    className="img-fluid"
                  />
                </Col>

                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Veterans In Event Planning</h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          Wedding Stories is the wedding wing of Vaidyas' Entertainment a 22 year old event & wedding planning company that endeavors to build events of any grandeur with unmatched grace, innovation. We have extensive experience of event planning, designing, staging, fabrication and executing grand weddings & events.



                         </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon03}
                    alt="AboutIcon01"
                    className="img-fluid"
                  />
                </Col>

                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Customer Satisfaction
</h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          Our team enjoys 98% repeat customers which is testimony to the lengths we go towards satisfying our customers.

                         </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon04}
                    alt="AboutIcon01"
                    className="img-fluid"
                  />
                </Col>

                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">We Want You To Relax & Feel The Magic

</h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          Our team's book is full of high calibre weddings and event professionals who are on a mission to make every detail of your wedding day truly special. Our priority for you is that you live your magical moments stress-free while your guests have a lot of fun.
                        </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>



            </Col>

            <Col md={6}>

              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon05}
                    alt="AboutIcon02"
                    className="img-fluid"
                  />
                </Col>
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">We Work with Trusted Vendors</h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          When you choose us, you have our trusted vendors to hire from. We are also comfortable & open to work with your vendors. We are transparent, honest and upfront in our dealings so we get honesty in return.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon06}
                    alt="AboutIcon02"
                    className="img-fluid"
                  />
                </Col>
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Passionate Team</h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          Our planning and execution teams are responsive, energetic, dynamic, tech-savvy and are qualified from renowned event management institutions. Our team has the ability and the resilience to take the ownership of every possible hiccup and proactively deal with it. They are well equipped to pull off weddings and events with an acute personalization.
                         </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

             <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon07}
                    alt="AboutIcon02"
                    className="img-fluid"
                  />
                </Col>
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Feedback Analysis</h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          We do a thorough feedback analysis, prepare in-house post-event reports in order to learn and improve and give our clients 100% transparency of our efforts. Our strategic planning involves extensive observation of details that helps us plan events with acute precision.


                         </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon08}
                    alt="AboutIcon02"
                    className="img-fluid"
                  />
                </Col>
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Wedding Themes And Trends</h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          Our team strives to be ahead of the wedding/events market. We are aware of what's in and out, what's practical and not, what's do-able and what's not. You want subtle? We can do it. You want to turn into a Bride-zilla? You got it! We want to make your vision a reality.
                         </p>
                         
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>









                      
            </Col>

        







          </Row>
        </Container>
      </section> 
    </>
  )
}

export default WhyTab
