import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./MilestoneCelebration.css";

import ChildrensBirthdayCelebrations from "../../images/Childrens-birthday-celebrations.jpg";
import CocktailEvents from "../../images/Cocktail-events.jpg";
import FestiveOccasions from "../../images/Festive-Occasions.jpg";
import IntimateFamilyFunctions from "../../images/Intimate-family-functions.jpg";
import LandmarkBirthdays from "../../images/Landmark-Birthdays.jpg";
import Weddinganniversarylandmarks from "../../images/Wedding-anniversary-landmarks.jpg";






const MilestoneCelebration = () => {
  return (
    <React.Fragment>
    
      <section className="inner_banner">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="position-absolute top-50 start-50 translate-middle ">
                <h2 className="text-uppercase">Milestone Celebrations</h2>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="mb-5">
        <Container >
          <Row>
            <Col lg="12 ">
              <div className="py-4 p-3 pitch_bg">
                <h2 className='text-center'>There's Always Something To Celebrate</h2>
              <p className='text-center'>
              Most moments in life are worth celebrating. It doesn't always have to be a birthday or an anniversary to party. Life offers plenty of opportunities to celebrate: your first date or first pet; a family tradition or first salary. From the traditional celebrations to milestones and special moments, we are there to make it personal, intimate and joyous for you. 
             </p>
            </div></Col>
            </Row>
            </Container>
            </section>
          
            
            
      <section>
        <Container>
          <Row className="d-flex align-items-center " >
            <Col lg={6}>
              <img src={LandmarkBirthdays} alt=""  className="img-fluid w-100"/>
            </Col>
            <Col  lg={6} >
           <h4> Landmark Birthdays  </h4>
           <p>Be it your 16th, 60th or 100 birthday - there's always a landmark that you would want to celebrate in a different way. Give us that responsibility and we will make sure your day is memorable. From themed birthday brunches to full-fledged cocktail parties, celebrity performances, stand-up shows, games and destination birthdays - we have done it all. </p>
             </Col>
          </Row>


      <Row className="d-flex align-items-center" >
        <Col  lg={6} className="order-1 order-lg-0">
           <h4>Wedding Anniversary Landmarks </h4>
           <p> We celebrate anything and everything related to marriage. So wedding anniversaries are special to us. Any decade of marriage - 10th, 20th, 30th or 50th anniversary - can be marked with a private intimate function of 50 people or a grand celebration of 1000. We plan and organize anniversaries of any scale for you. </p>
             </Col>
             <Col lg={6}>
              <img src={Weddinganniversarylandmarks} alt=""  className="img-fluid w-100"/>
            </Col>
        </Row>


        <Row className="d-flex align-items-center " >
           
        <Col lg={6}>
              <img src={IntimateFamilyFunctions} alt=""  className="img-fluid w-100"/>
            </Col>
           <Col  lg={6} >
           <h4>Intimate Family Functions  </h4>
           <p>We plan and professionally organize other important milestones such as baby showers,  house warming, musical housies, new baby arrival functions, retirement parties, farewells and welcome functions.  </p>
             </Col>
        </Row>




        <Row className="d-flex align-items-center " >
           <Col  lg={6} className="order-1 order-lg-0">
           <h4>Children’s Birthday Celebrations  </h4>
           <p>Children especially need any excuse to have fun or to celebrate. We can plan and organize themed birthdays/ carnivals with  magicians,jugglers and clowns, graduation celebration etc.  </p>
             </Col>

             <Col lg={6}>
              <img src={ChildrensBirthdayCelebrations} alt=""  className="img-fluid w-100"/>
            </Col>
        </Row>


        <Row className="d-flex align-items-center " >
        <Col lg={6}>
              <img src={CocktailEvents} alt=""  className="img-fluid w-100"/>
            </Col>

           <Col  lg={6} >
           <h4>Cocktail Events</h4>
           <p>We have also planned and organized special cocktail events  where we have entertained the guests with Ghazal , Folk music , Sufi and Qawwali singers .We can also arrange for unique bartending services and acts. </p>
             </Col>


        </Row>


        <Row className="d-flex align-items-center " >
           <Col  lg={6} className="order-1 order-lg-0">
           <h4>Festive Occasions </h4>
           <p>Hire us for home décor for festive celebrations like Diwali, Janmashatmi etc… and pre wedding décor and watch us weave our magic. </p>
             </Col>

             <Col lg={6}>
              <img src={FestiveOccasions} alt=""  className="img-fluid w-100"/>
            </Col>
        </Row>
        </Container>
      </section>





      <section className="about_section_home py-4 mt-4">
    <Container className="">
    <Row>
    <Col lg="12">
    <div className="cloud-wrap text-center">
  
    <h2 className='text-center'>There's Always Something To Celebrate</h2>

    Birthdays Or Anniversaries Of Any Scale, 3-Day Destination Event Or Function, Themed Décor / Event Decor Design And Management, Entertainment, DJ And Cocktail Avents, Our Signature Interactive Game Shows Bollywood Badshah, Bollywood Tambola, Bollywood Talkies, Bollywood Fun Funtakshari, Family Games, Pool Parties, Antaksharis, Musical Housie, Interactive Game Shows /Karaoke Singing, Hotel Or Resort Brunches Based On Themes, Themed Invites Like Illustrated Cards, E-invites, Sustainable Invitation Cards etc, Return Gifts/Hamper Selection, Purchases, and Wrapping, Carnivals/Fashion Shows Arranging Entertainers Such As Clowns, Stand-Up Comedians, Singers, Jugglers, Themed Children's Events and Many More...




</div>

    </Col>

    </Row>


    </Container>


    </section>









    </React.Fragment>
  );
};

export default MilestoneCelebration;
