import React from "react";
import "../Footer/Footer.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { NavLink } from "react-router-dom";
import FooterLogo from "../../images/logo-footer.png";

import { FaFacebookF, FaLinkedin } from 'react-icons/fa';
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaPinterest } from 'react-icons/fa';

const Footer = () => {

 
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <React.Fragment>


  



      <section className="footer py-4">
            <Container className="">
          <Row className="py-2 justify-content-md-center">
            <Col lg="3">
              <img src={FooterLogo} alt="Logo" className="footer_logo mb-2"/>
            </Col>

            <Col lg="5" className="">
              <Row md={12}>
                <h5 className="text-left">Quick Links</h5>
                <Col>
                  <ul>
                    <li>
                      <NavLink to="/About/What-We-Do" onClick={scrollToTop} >About</NavLink>
                    </li>
                    <li>
                      <NavLink to="/WeddingPlanning/Wedding-Planning" onClick={scrollToTop} >
                        Wedding Planning
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/Wedding-Entertainment" onClick={scrollToTop} >
                        Wedding Entertainment
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/Destination-Wedding" onClick={scrollToTop}>
                        Destination Weddings
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to="/Testimonials" onClick={scrollToTop}>
                        Testimonials
                      </NavLink>
                    </li>
                  </ul>
                </Col>

                <Col>
                  <ul>
                    <li>
                      <NavLink to="/Resources/Faqs" onClick={scrollToTop}>
                        FAQ's
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/Resources/Blogs" onClick={scrollToTop}>
                        Blogs
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/Gallery/Photos" onClick={scrollToTop}>
                        Gallery
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/Contact-Us" onClick={scrollToTop}>
                        Contact
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/Sitemap" onClick={scrollToTop}>
                        Sitemap
                      </NavLink>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col lg="4">
              <div className="mybulletlist2">
                <h5 className="text-left"> Contact Details</h5>
                <ul>
                
                  <li>
                    1-B, 111, Varun Arcade, G.B. Service Road, Opposite R Mall,
                    Thane West - 400610
                  </li>
                
                  <li> Jineshh Vaidya: +91 9820332999</li>
               
                 <li>Rajitha Vasudevan Khan: +91 9833743015</li>
                
                  <li>
                    <a href="mailto:hello@weddingstories.co.in" className="">
                      hello@weddingstories.co.in
                    </a>
                  </li>

                </ul>

                <div className="rounded-social-buttons">
                  <a className="social-button facebook" href="https://www.facebook.com/weddingstorieseventsandentertainment" target="_blank" rel="noreferrer"><FaFacebookF /> </a>
                  <a className="social-button youtube" href="https://www.youtube.com/@weddingstoriesyt" target="_blank" rel="noreferrer" ><FaYoutube /></a>            
                  <a className="social-button instagram" href="https://www.instagram.com/weddingstoriesig" target="_blank" rel="noreferrer" > <FaInstagram /></a>
                  <a className="social-button instagram" href="https://in.pinterest.com/weddingstories" target="_blank" rel="noreferrer" > <FaPinterest /></a>
                  <a className="social-button instagram" href="https://www.linkedin.com/company/wedding-stories-events-entertainment" target="_blank" rel="noreferrer" > <FaLinkedin /></a>
                </div>


                
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="about_section_home py-3 ">
    <Container className="">
    <Row>
    <Col lg="12">
    <div className="cloud-wrap small">

Wedding Planners in India | List of Wedding Planners in Mumbai | Wedding Planners in Delhi | Wedding Planners in Nagpur  | Top 10 Wedding Planners in Navi Mumbai | Wedding Planners in Jaipur | List of Wedding Planners in Pune | Wedding Planners in Pune | Budget Wedding Planners in Mumbai | Wedding Planners in Thane | Destination Wedding Planners in Mumbai | Top 10 Wedding Planners in Mumbai | List of wedding Planners in Delhi | Wedding Planners in Mumbai | List of wedding Planners in Navi Mumbai | Wedding Planners in Kolhapur | Budget Wedding Planners in Navi Mumbai | Wedding planners cost Destination | Wedding Planners in Navi Mumbai | Destination wedding Planners in Delhi | Top 10 Wedding Planners in Delhi | Wedding Planners in Nashik | Wedding Planners near me | Budget Wedding Planners in Delhi

</div>

    </Col>

    </Row>


    </Container>


    </section>

      <Container>
        <Row className="">
          <Col lg="12 d-flex flex-column flex-lg-row justify-content-between p-2 ">
          <div className="small text-center text-lg-left">  Copyright © Wedding Stories | All Rights Reserved</div>
          <div className="small text-center text-lg-left">  Designed & Maintained by Goradia Infotech</div>
            </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Footer;
