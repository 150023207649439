import React from 'react'
import './CommunityEvents.css'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BiSolidDownArrow } from "react-icons/bi";
import EntertainmentNights from '../../../images/entertainment-nights.png'
import PlanningcoordinatingannualprogramIco from '../../../images/Planning-coordinating-annual-program.png'
import ComprehensiveReligiousEventPlanning from '../../../images/Comprehensive-Religious-Event-Planning.jpg';
import Socialgroups from '../../../images/Social-groups.jpg';
import ServiceorganizationsClubs from '../../../images/Service-Organizations-Clubs.jpg';
import Largeresidentialsocieties from '../../../images/Large-residential-societies.jpg'
import Communitytradingevents from '../../../images/Community-trading-events.jpg'
import Organizingmusicalnights from '../../../images/organizing-musical-nights.jpg'
import PlanningCoordinatingAnnualPrograms from '../../../images/planning-coordinating-annual-programs.jpg'


const CommunityEvents = () => {
  return (
    <React.Fragment>

<section className="inner_banner">
<Container>
  <Row>
    <Col lg={12}>
    <div className="position-absolute top-50 start-50 translate-middle ">
        <h2 className="text-uppercase">Community Events</h2>
         </div>
      </Col>
      </Row>
      </Container> 
</section>

<section className="mb-5">
        <Container>
          <Row>
            <Col lg="12 ">
              <div className="py-4 p-3 pitch_bg">
                <h2 className='text-center'>We Have The Pulse Of Community Events</h2>
              <p className='text-center'>
              We have a rich experience of 22+  years in planning and coordinating events of any scale. In addition, we can conceptualize, plan and execute the entertainment segment of your events most creatively. Our forte is to create  mesmerizing, memorable and eye-catching events . We know what it takes to make a community event an absolute success. 
               </p>
            </div></Col>
            </Row>
            </Container>
</section>

<section>
        <Container>
          <Row className="d-flex align-items-center " >
            <Col lg={6}>
              <img src={ComprehensiveReligiousEventPlanning} alt=""  className="img-fluid w-100"/>
            </Col>
            <Col  lg={6} >
           <h4 className='mt-2'> Comprehensive Religious Event Planning </h4>
           <p>At Wedding Stories, we specialize in designing and planning religious events that honor your traditions and create meaningful experiences. Recently, we successfully organized a grand Bhagwat Saptah, of 20000 +attendees and 100 + crew to manage and 10,000+ devotees partaking in prasad, bringing together the spiritual and community aspects of this sacred event.  </p>
           <p>Our dedicated team ensures every detail is meticulously handled, from venue selection and decoration to coordinating rituals and guest services. Trust us to manage your religious celebrations with the utmost respect and care, making them truly unforgettable. </p>
             </Col>
          </Row>


      <Row className="d-flex align-items-center" >
        <Col  lg={6} className="order-1 order-lg-0">
           <h4 className='mt-2'>Social Groups</h4>
           <p>Our team has an experience of two decades of organizing programs for specific communities such as Jain Social Groups, Jain Jagruti Centres,, Gujarati Community Groups, Marwari Social Groups, Nair Groups, to name a few. </p>
             </Col>
             <Col lg={6}>
              <img src={Socialgroups} alt=""  className="img-fluid w-100"/>
            </Col>
        </Row>


        <Row className="d-flex align-items-center " >
           
        <Col lg={6}>
              <img src={ServiceorganizationsClubs} alt=""  className="img-fluid w-100"/>
            </Col>
           <Col  lg={6} >
           <h4 className='mt-2'>Service Organizations / Clubs  </h4>
           <p>Clubs and service organizations such as Lions and Lioness Clubs, Rotary Club of India, housing societies often organize high-stake large and small-scale events. Be it their annual foundation days, Diwali, Christmas ,Holi or New Year gatherings, interactive family retreats, fashion shows, children’s talent shows, outdoor adventures or picnics - our team adds an extra layer of oomph and creativity into these events.   </p>
             </Col>
        </Row>




        <Row className="d-flex align-items-center " >
           <Col lg={6} className="order-1 order-lg-0">
           <h4 className='mt-2'>Large Residential Societies </h4>
           <p>
           We get hired by large townships and residential societies for their year-long events such as Ganapati Pooja, Navratri, Dandia nights, Diwali or Holi functions, Christmas or New Year bashes, foundation days or other annual programs. 
           </p>

             </Col>

             <Col lg={6}>
              <img src={Largeresidentialsocieties} alt=""  className="img-fluid w-100"/>
            </Col>
        </Row>


        <Row className="d-flex align-items-center " >
        <Col lg={6}>
              <img src={Communitytradingevents} alt=""  className="img-fluid w-100"/>
            </Col>

           <Col  lg={6} >
           <h4 className='mt-2'>Community Trading Events </h4>
           <p>Many communities also organize special trading/business exchange events that require a different touch. We have a dedicated team that holds talks, conferences, trading fairs, entrepreneurship events for specific communities that are keen on bringing their merchants together. </p>
             </Col>


        </Row>


   
        </Container>
</section>



<section className=" mt-5 mb-4">
        <Container className=''>
          <Row>
            <Col lg="12" className=''>
              <div className='pitch_bg p-3'>
            <h2 className='text-center'>Our Areas Of Expertise </h2>
            </div>
            </Col>
            </Row>
            </Container>
</section>

<section >
        <Container>
          <Row>
            <Col lg={12}>
              <ul className="servicesinsidenew mb-5">
                <li>
                  <div className="flip-container-main">
                    <div className="flip-container">
                      <div className="flipper">
                        <div className="front">
                          <div className="front-icon">
                            <img src={EntertainmentNights} alt="icon01" />
                          </div>
                          <span className="name">Organizing Musical / Entertainment Nights </span>
                        </div>
                        <div className="back">
                          <div className="border334">
                          <div className='uldiv'>
                            <ul>
                              <li>Live Band Musical nights </li>
                              <li>DJ nights</li>
                              <li>Comedy</li>
                              <li>Entertainment</li>
                              <li>Antakshari</li>
                              <li>Game Shows</li>
                              <li>Ghazal / Sufi Evenings</li>
                              <li>Bhajan Sandhyas </li>
                              <li>Navratri Live Band</li>
                              <li>Conceptualized  Bollywood shows</li>
                            </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hoverimage">
                      <img src={Organizingmusicalnights} alt="" />
                    </div>
                  </div>

                  <div className="flip-container-main">
                    <div className="flip-container">
                      <div className="flipper">
                        <div className="front">
                          <div className="front-icon">
                            <img src={PlanningcoordinatingannualprogramIco} alt="icon01" />
                          </div>
                          <span className="name">Planning & Coordinating Annual Programs / Monthly Programs
 </span>
                        </div>
                        <div className="back">
                          <div className="border334">
                           <div className='uldiv'>
                            <ul>
                              <li>Foundation days </li>
                              <li>Diwali </li>
                              <li>New Year</li>
                              <li>Christmas</li>
                              <li>Kites Festivals</li>
                              <li>Independence Day programs</li>
                              <li>Annual picnics</li>
                              <li>Carnivals</li>
                              <li>Spiritual Discourses</li>
                              <li>Motivational Speakers</li>
                              <li>Interactive Games</li>
                             </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
               
                    <div className="hoverimage">
                      <img src={PlanningCoordinatingAnnualPrograms} alt="" />
                    </div>


                  </div>
                </li>

        

          
            


    
              </ul>
            </Col>
          </Row>
        </Container>

</section>

<section>
        <Container className='mb-4'>
       
          <Row>
            <Col lg="12" className='mb-4'>
              <div className='pitch_bg p-3'>
            <h2 className='text-center'>How Do We Plan Community Events For You?</h2>
            </div>
            </Col>
            </Row>


<Row>
<Col lg={6}>
     <div className="accordion">
              <div className="accordion-item border-0 p-4 rounded-0 mb-4">
                  <div className="d-flex justify-content-between">
                    <h5 className="mb-0 ">
                     1.  We conceptualize event 
                    </h5>
                    <BiSolidDownArrow />
                  </div>

                  <div className="accordion-item-content ">
                    <div className="mt-2">
                      <hr />
                      <p>
                      Any big or small event for us is an opportunity to create memories and experiences through entertainment. We always suggest our clients on the possible entertainment themes that may go well with the event. From conceptualizing a catchy name or event tagline, branding of the event, to selecting a theme and getting invites designed - we plan and execute the entire event for you. 
                      </p>
                    </div>
                  </div>
                </div>

        </div>
        </Col>

          
  <Col lg={6}>
<div className="accordion">
    <div className="accordion-item border-0 p-4 rounded-0 mb-4">
      <div className="d-flex justify-content-between">
        <h5 className="mb-0 ">
         2. We plan 
        </h5>
        <BiSolidDownArrow />
      </div>

      <div className="accordion-item-content ">
        <div className="mt-2">
          <hr />
          <p>
          We create a systematic plan of execution apart. This keeps our process transparent for you and you are aware of what to expect. 
          </p>
        </div>
      </div>
    </div>
  

 </div>
</Col>
 </Row>


 <Row>
<Col lg={6}>
     <div className="accordion">
              <div className="accordion-item border-0 p-4 rounded-0 mb-4">
                  <div className="d-flex justify-content-between">
                    <h5 className="mb-0 ">
                    3. We entertain
                    </h5>
                    <BiSolidDownArrow />
                  </div>

                  <div className="accordion-item-content ">
                    <div className="mt-2">
                      <hr/>

                      <p>
                      Our founder Jineshh Vaidya is a talented singer who has travelled around the world performing in several shows and events. He has a keen sense of sound, lights and stage  design. With a natural bent for music and a wide network of artists and musicians from around the world, he has the ability to beautifully design entertainment of any magnitude. 
                      </p>

                    </div>
                  </div>
                </div>

        </div>
        </Col>

          
  <Col lg={6}>
<div className="accordion">
    <div className="accordion-item border-0 p-4 rounded-0 mb-4">
      <div className="d-flex justify-content-between">
        <h5 className="mb-0 ">
         4. We execute 
        </h5>
        <BiSolidDownArrow />
      </div>

      <div className="accordion-item-content ">
        <div className="mt-2">
          <hr />
          <p>
          Our ground team is present at the event for the execution of the event plan. Our ground staff and background team ensure that every small detail of the event is executed in its original form. 
          </p>
        </div>
      </div>
    </div>
  

 </div>
</Col>
 </Row>




 </Container>
</section>










</React.Fragment>
  )
}

export default CommunityEvents
