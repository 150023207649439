import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BiSolidDownArrow } from "react-icons/bi";
import faqsIcon from "../../../images/faqs-icon.jpg";
import { NavLink, Link } from "react-router-dom";
import "./Faqs.css";

const Faqs = () => {

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <React.Fragment>
      <section className="inner_banner">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="position-absolute top-50 start-50 translate-middle ">
                <h2 className="text-uppercase">Faq's</h2>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="custom_accordian mb-3">
        <Container>
          <Row className="">
          <Col lg={6}>
              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={faqsIcon}
                    alt="AboutIcon01"
                    className="img-fluid"
                  />
                </Col>

                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">
                        How many weddings have you planned so far?

                        </h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          We have lost the count but we have done all kinds of
                        weddings in the last 20 years. However, it’s not the
                        number of weddings but the quality of the weddings that
                        we have done that enables us to make yours truly grand.

                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={faqsIcon}
                    alt="we_icon"
                    className="img-fluid"
                  />
                </Col>
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Do you do wedding decor?</h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content">
                        <div className="mt-2">
                          <hr />
                          <p>
                Yes, Of course We do. However, if you have your own vendors in mind, we will be happy to work with them as well. As wedding planners, our duty is to work with whatever you have. 
                </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>



              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={faqsIcon}
                    alt="AboutIcon01"
                    className="img-fluid"
                  />
                </Col>

                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">What all do you do in a wedding?
                        </h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                  We are wedding planners, so we design, plan,
                  coordinate,manage and execute every aspect of your
                  wedding. To know the full length of our services, check
                  out <NavLink to="/WeddingPlanning/Wedding-Planning" onClick={scrollToTop}>
                  Wedding Services
                </NavLink>.
                </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>




              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={faqsIcon}
                    alt="AboutIcon01"
                    className="img-fluid"
                  />
                </Col>

                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">
                        Do you have experience of destination weddings outside India?
                        </h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                Yes. Ever since we began planning weddings 20 years ago, we have done foreign as well as domestic destination weddings. We have planned and organized weddings in Madagascar, Bahrain, London, Kenya, USA, Bangladesh, China, Dubai, Philippines so far. 
                </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>




              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={faqsIcon}
                    alt="AboutIcon02"
                    className="img-fluid"
                  />
                </Col>
             
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Can you do a grand wedding?</h5>
                        <BiSolidDownArrow />
                      </div>
                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr/>
                          <p>
                          Yes. Tell us your budget and we will create a grand wedding within it. That's our speciality. 
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

              </Row>










              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={faqsIcon}
                    alt="AboutIcon02"
                    className="img-fluid"
                  />
                </Col>
             
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Why should we hire you?</h5>
                        <BiSolidDownArrow />
                      </div>
                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr/>
                          <ul>
<li>	You get a grand wedding in your budget </li>
<li>	We work amicably with your vendors</li>
<li>	We generate vendor reports post the wedding so you know how the budget was allocated </li>
<li>	We are honest, we will never try to make money from vendor commissions or any such unscrupulous practices</li>
<li>	We plan and consult and truly get to know you before we start on the preparations</li>
<li>	We give you peace of mind. We create flawless weddings while you have fun living every moment of your wedding</li>
<li>	We have a very practical approach to wedding planning with our 20 years of experience in the industry. </li>
<li>	We know the latest trends in wedding industry</li>
</ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

              </Row>

              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={faqsIcon}
                    alt="AboutIcon02"
                    className="img-fluid"
                  />
                </Col>
             
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">What's the process of hiring you?</h5>
                        <BiSolidDownArrow />
                      </div>
                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr/>
                          <p>
                 Just pick up your phone and call us from the number given in &nbsp;
                 <NavLink to="/Contact-Us" onClick={scrollToTop}>
                 Contact Us </NavLink>&nbsp;              
                 section or fill up the form below. 
                 </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

              </Row>


              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={faqsIcon}
                    alt="AboutIcon02"
                    className="img-fluid"
                  />
                </Col>
             
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">What size of weddings can you plan? </h5>
                        <BiSolidDownArrow />
                      </div>
                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr/>
                          <p>
                          It may be a private wedding with 50 pax or a grand wedding of 2,000 pax - we have done it all, most exquisitely. 
                 </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

              </Row>







            </Col>

            <Col lg={6}>



              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={faqsIcon}
                    alt="AboutIcon01"
                    className="img-fluid"
                  />
                </Col>

                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">
                        I only want you to organize Sangeet and Mehendi, can you do that?
                        </h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                Yes. If your need is only for Sangeet and Mehendi, our team can organize that for you beautifully. You can hire us for the entire wedding or only for special  occasions. Our special Wedding Entertainment services ensure that we enthrall your guests with a line-up of Bollywood or local celebrities, singers, comedians, anchors and DJs. 
                </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={faqsIcon}
                    alt="AboutIcon02"
                    className="img-fluid"
                  />
                </Col>
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0"> How soon should I contact you before the wedding?</h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
        
                <p>
                We recommend contacting us as soon as you know the dates of your wedding. The sooner, the better. By reaching out early, we can guide you through the planning process, ensuring every detail is perfect for your special day. However, our experience of 20 years has helped us to slip into any shoes at any time.
                </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>



              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={faqsIcon}
                    alt="AboutIcon02"
                    className="img-fluid"
                  />
                </Col>
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">How much do you charge when we hire you?</h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                <NavLink to="/WeddingPlanning/Wedding-Planning" onClick={scrollToTop}>
                Our pricing is a one-time professional fee.
                </NavLink>
               </p>
                <p>
                We curate  customized packages  that perfectly fits your needs and budget. Before you give up on us, just pick up the phone and ask, and we will see what we can do best in your budget. We are not necessarily expensive. 
                </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>


              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={faqsIcon}
                    alt="mw_icon"
                    className="img-fluid"
                  />
                </Col>
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Do you do any other events apart from weddings?</h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                Yes we can plan any event or occasion for you. But to list a few: anniversaries, birthdays, golden anniversaries, themed birthdays of any scale, themed events like casino setting/ Bollywood night / Egyptian/medieval/70's style office party/Awards ceremony/Moroccan wedding/a masquerade party, 50th/60th birthday with celebrity performers or religious/community events such as Dandia DJ nights, personal functions such as Godh Bharai - we have done all kinds of functions and events. Check out <Link  to="/Community-Events" onClick={scrollToTop}> Community Events</Link> and <Link  to="/Milestone-Celebration" onClick={scrollToTop}> Milestones.</Link> 
                </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

          




              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={faqsIcon}
                    alt="AboutIcon02"
                    className="img-fluid"
                  />
                </Col>
             
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Have you done multicultural weddings?</h5>
                        <BiSolidDownArrow />
                      </div>
                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr/>
                          <p>
                 Yes. Another one of our specialties is managing cultures of both the groom’s and bride’s families. We have a diverse team of professionals from all backgrounds that helps us combine the knowledge of all cultures. 
                 </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

              </Row>

              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={faqsIcon}
                    alt="AboutIcon02"
                    className="img-fluid"
                  />
                </Col>
             
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Have you done weddings other than Hindu?</h5>
                        <BiSolidDownArrow />
                      </div>
                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr/>
                          <p>
                          Yes. We have successfully planned and executed Parsi, Muslim, Sikh, Christian and Bahai weddings. 
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

              </Row>

              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={faqsIcon}
                    alt="AboutIcon02"
                    className="img-fluid"
                  />
                </Col>
             
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">What kind of wedding themes have you done? </h5>
                        <BiSolidDownArrow />
                      </div>
                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr/>
                          <p>
                          We have worked on various wedding themes such as Bollywood, Vintage, Rustic, Arabic, Moroccan, Rajasthani Royal, Snow/Kashmiri Style, Punjabi, Rajputana, Kshatriya theme, Vrindavan, Bohemian or specific on-demand themes such as The Great Gatsby, Bahubali, Elephant theme, Panchtatva-based theme, Mughlai, Flamingo, Sparkling mirror, Lavender, sustainable wedding theme etc. 
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

              </Row>

              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={faqsIcon}
                    alt="AboutIcon02"
                    className="img-fluid"
                  />
                </Col>
             
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">How are you different from other event/wedding planners in Mumbai or other parts of India?</h5>
                        <BiSolidDownArrow />
                      </div>
                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr/>
                          <p>
                We stand by you. You are the center of our attention when you hire us. As a result, we ensure that your personality truly comes out from your wedding. We work within your budget and make any wedding look grand. We will never cheat you. 
                </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

              </Row>








            </Col>
            
          </Row>

          
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Faqs;
