import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import WPtab from './WPtab';
import WPaTab from './WPaTab';
const WeddingPlanning = () => {
  return (
    <React.Fragment>
  <section className="inner_banner">
  <Container>
      <Row>
        <Col lg={12}>
        <div className="position-absolute top-50 start-50 translate-middle ">
        <h4 className="d-block text-center">Wedding Planning</h4>
            <h2 className="text-uppercase">OUR SERVICES</h2>
             </div>
          </Col></Row>
          </Container> 
    </section>


    <section className="">
        <Container>
          <Row>
            <Col lg="12 py-2">
              <div className="">
                <p className=''>
                Often times, we as wedding planners feel that in India people generally are not very clear about the role and how hiring the services of a wedding planner would be useful for them and hence the common questions like
                </p>
          <ul>
            <li>What is your scope of work?</li>
            <li>What would be your fees and what would it include?</li>

          </ul>

            <p className='fw-bold'>We have tried our best to mention our scope of work for the fees we charge below.
            </p>




            </div></Col>
            </Row>
            </Container>
            </section>



            <section className="">
        <Container className=''>
          <Row>
            <Col lg="12 ">
    

              <div className="about_section_home p-3 ">
                <h2 className=''>
                Our Role<span className='d-block'>Before The Wedding</span>

                </h2>
                    </div></Col>
            </Row>
            </Container>
            </section>

            <section>
              <WPtab/>
      </section>

      <Container className=''>
          <Row>
            <Col lg="12 ">
              <div className="about_section_home p-3 ">
                <h2 className='mb-0'>During the Wedding</h2>
              </div>
              </Col>
            </Row>
            </Container>


      <section>
       <WPaTab/>
      </section>

      <section>


      <Container className=''>
          <Row>
            <Col lg="12 ">
            <hr />
              <div className="">
                <h3 className=''>We Will Be Like Your Shadow</h3>
                <p>
                Our team makes your wedding dream come true. We will be by your side like a shadow. Every important member of your family, bride, groom, parents will have a helping hand to fetch things, get pooja things, bring clothes and jewellery at the venue, put suits back on the hangers, pull a chair for you, safeguard important articles - you name it! We will oversee every detail.
                </p>
              </div></Col>
            </Row>
            </Container>



      </section>



    </React.Fragment>

  )
}









export default WeddingPlanning
