import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import PhotosItems from '../PhotosItems/PhotosItems';

import { NavLink } from "react-router-dom";


const Photos = () => {
  return (
    <React.Fragment>
      
      <section className="inner_banner">
  <Container>
      <Row>
        <Col lg={12}>
        <div className="position-absolute top-50 start-50 translate-middle ">
            <h2 className="text-uppercase">Gallery - Photos</h2></div>
          </Col></Row>
          </Container> 
    </section>


 <section>
<Container>
<Row className='text-center mb-4'> 
    <Col>  
    <NavLink to="/Gallery/Photos"><Button variant="secondary me-3">Photos</Button></NavLink>  
    <NavLink to="/Gallery/Videos"><Button variant="secondary me-3">Videos</Button></NavLink>  

     </Col>
      </Row>
</Container>

<Container className="mb-4">
        <Row >
       <PhotosItems/>
        </Row>
      </Container>




</section>



    </React.Fragment>
  )
}

export default Photos
