import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import './TestimonialsGrid.css'
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import star from '../../images/star.png'
import poupLogo from '../../images/logo.png'

const TestimonialsGrid = () => {
    const [currentTestimonial, setCurrentTestimonial] = useState(null);
    const testimonials = [
        {
          id: 1,
          content: [
            `After discovering Wedding Stories on Google in September 2023, my husband and I had the pleasure of working with them for our wedding in Igapturi in March 2024. Planning a wedding in India as an Indian-American bride living in the USA was challenging, but Rajitha and Jinesh partnered with us to successfully execute a logistically complex event that both Indian and American guests raved about.`,

            `Rajitha and Jinesh listened attentively to our vision and goals. Their coordination of vendors, transportion of items, vendors and guests from Mumbai to the venue and management of timelines were excellent. Without their expertise, the wedding would have been very stressful and chaotic, especially as the venue was below our expectations (we booked the venue before we hired wedding stories, so not on them).`,

            `One of the standout features of their service was their ability to match the right staff members to each specific role. For example, they assigned me (bride) an incredibly attentive shadow (Yamani) who ensured I was well taken care of throughout the events. They also provided a thoughtfully escort (Sakshi) for our foreign guests, which made them feel welcomed and at ease in unfamiliar surroundings.`,

            `To anyone considering their services, I wholeheartedly recommend hiring Wedding Stories from day 1 for their expertise, dedication, and commitment to creating unforgettable wedding experiences They definitely live up to their reviews!`,
            
          ],
          smallcontent: [
            `“This product has changed my life! The quality is outstanding and the customer service was top-notch. Highly recommend!...”`,
          ],
          author: 'Laya Mallela ',
          dis: 'Bride'
        },
        {
          id: 2,
          content: [
            `Wedding Stories was incredible to work with and gave us a wedding that exceeded our expectations! Jinesh and Rajitha were responsive, personal, and so positive - we really felt like they were part of our family. They even came to our pre wedding shoot at 7am!`,
            `Our wedding was planned in only a few months and it would not have been possible without their diligent work. I know my parents in law were so happy to see their royal wedding vision come to life, and we had such fun being at the center of it as bride and groom! Wedding Stories made sure everything ran smoothly, including our ceremony which flowed very efficiently and still included all of the traditions and shlokas we wanted. As an American coming into a new country and culture, I also really appreciated how much Jinesh and Rajitha sought to include me in the wedding planning process and executed some of the decor elements I really wanted.`,
            `A final appreciation for how they ended our wedding weekend, with a fun family dinner that included their Bollywood Badshah game and more singing and dancing. They made sure every moment of our wedding was filled with love and laughter!`
          ],
          smallcontent: [
            ``,
          ],
          author: 'Jennifer Welden Uttarwar ',
          dis: 'Bride',

        },
        {
          id: 3,
          content: [
            `From the first day we met at the venue, I had instantly felt a connection with the team. I believed in every idea and every suggestion you made and all of it came to fruition in the 2 days of my wedding!`,
            `From calling Rajitha an hour before the haldi for arranging flower petals at short notice, to seeing Jinesh at the Haldi just assuring us that everything is taken care of (all only through his eyes), to seeing Rajitha surprisingly showing us her hosting talents - i am incredibly impressed by them both! Each idea they gave us was executed to perfection so there were no gaps in what was suggested and what was delivered! But then again - i had a gut feeling that that would be the case with them! They gained our trust at the first meeting and we are honoured to have had you in our corner throughout this!`,
            `Thank you ever so much Jinesh and Rajitha! You make a great team! Excellent work by Wedding Stories!`
          ],
          smallcontent: [
            ``,
          ],
          author: 'Simran Modak',
          dis: 'Bride',
        },

        {
            id: 4,
            content: [
              `Where do I begin? Rajitha and Jinesh were like my lifesavers for the wedding. They are really sweet, helpful, caring, energetic, and very easy to work with. They brought details to my attention that I had never even thought of. During our events they had everything handled and taken care of and I didn’t have to worry about a thing. I couldn’t have imagined having an amazing time during my wedding festivities without Wedding Stories. We are very grateful to the entire team and all they did for us to ensure a great start for our journey of a lifetime.`
            ],
            smallcontent: [
              ``,
            ],
            author: 'Sravani Rao',
            dis: 'Bride',
          },

          {
            id: 5,
            content: [
              `The team of Wedding Stories by Jineshh & Rajitha were present at our wedding in Madagascar. We spent an incredible time with the team, the music and the management was amazing. We strongly recommend.`
            ],
            smallcontent: [
              ``,
            ],
            author: 'Inara & Denil ',
            dis: 'Bride & Groom',
          },
  
          {
              id: 6,
              content: [
               `Dr J & Rajitha come with years of experience in event management and planning. They have honestly been the best guides and give out honest opinions in every matter which is what my family and i were looking for when we hired them my wedding. Our guests also applauded their services and team. I, as a bride did not have to worry about a thing during the D-Days. Would highly recommend them.`
              ],
              smallcontent: [
                ``,
              ],
              author: 'Milouni Meswani',
              dis: 'Bride',
            },

            {
                id: 7,
                content: [
                  `Thanks to team Wedding Stories for a beautiful experiential wedding of our daughter at Touchwood Bliss. Special mention for Sakshi who took utmost care of our guests from US, and Yamini who was the bride's shadow and did other tasks too. Of course Rajitha was the best. She is a wonderful person - Works very hard, very detail oriented, and very patient. Enjoyed working with her and Jinesh ji. I would whole hearted recommend Wedding Stories to my friends. Thank you!`
                ],
                smallcontent: [
                  ``,
                ],
                author: 'Bala & Uma',
                dis: 'Bride',
              },
              {
                  id: 8,
                  content: [
                    `We had a great time in our boys' wedding thanks to the team effort of Rajitha and Jinesh They are fabulous team and very professional too They made sure that all of us had fun at all the events and made the whole wedding totally stress-free for us`
                  
                  ],
                  smallcontent: [
                    ``,
                  ],
                  author: 'Rimzim Jialdasani ',
                  dis: 'Grooms Aunt',
                },
                {
                    id: 9,
                    content: [
                      `I want to share my experience with the event team. Their dedication and professionalism made our special day unforgettable. There was seamless co-ordination and everything was executed flawlessly. Thankyou for your hardwork, attention to detail and for ensuring everything ran smoothly. It was great working with the team.`
                    ],
                    smallcontent: [
                      ``,
                    ],
                    author: 'Rajesh Chokhani',
                    dis: 'Bride’s Dad',
                  },
                  {
                    id: 10,
                    content: [
                      `Highly impressed with Rajitha's attention to detail, transparency in communication and high level of efficiency. She is very reliable and knows how to get the work done. The team was always available to assist, and everyone maintained a cheerful attitude and environment. Definitely recommended working with Wedding Stories. Thank you for all your hard work and making our event successful and special.`
                    ],
                    smallcontent: [
                      ``,
                    ],
                    author: 'Riti Goel',
                    dis: `Groom's Sister`
                  },
                  {
                    id: 11,
                    content: [
                      `Hello,`,
                      `Thanks ,Had a great experience with Dr. J , Rajitha & entire team and were always easily available to plan and discuss on execution of our event.`,
                      `Very well planned our event ,superb & new concept with innovative ideas . Perfectly managed entire event right from selection of invitation card with gift , theme of party , coordination with singers and their band , selection of menu and much more . Nicely understood what we needed and made our event a big success.`
                    ],
                    smallcontent: [
                      ``,
                    ],
                    author: 'Poorvi Chitalia',
                    dis: `Client`
                  },
                  {
                    id: 12,
                    content: [
                      `Wedding Stories did a fantastic job at curating a truly elegant & traditional Marathi wedding for us 👍👏 hats off specifically to Jinesh & Rajitha for making the event flow smoothly and keeping the energy upbeat and full of love!`,
                    ],
                    smallcontent: [
                      ``,
                    ],
                    author: 'Nicket Uttarwar',
                    dis: `Groom`
                  },
                  {
                    id: 13,
                    content: [`Jinesh and Rajitha are a great team and with their excellent team do a great job. My best wishes to the Wedding Stories team.`],
                    smallcontent: [``],
                    author: 'Sundar Ramanathan', 
                    dis: `Client `
                  },
                  {
                    id: 14,
                    content: [`The team is great and on their toes with last minute requests. Commendable work and we thank them for making our big day special. Looking forward to have them on our special days ahead.`],
                    smallcontent: [``],
                    author: 'Anusha Jain ', 
                    dis: `Bride`
                  },
                  {
                    id: 15,
                    content: [`Our entire event was beautifully planned and was exactly how we had imagined it. Great team work and the singers were amazing and extremely talented.( Jinesh sir, you sang really well too.) Had wonderful experience with entire team.`],
                    smallcontent: [``],
                    author: 'Henisha C', 
                    dis: `Client `
                  },
                  {
                    id: 16,
                    content: [`Dr Jignesh Vaidya is a one of most passionate person I have ever met. We never felt that he is an event organizer. He made sure that our guest are most comfortable. All events, food everything was taken care of exceptionally well. His partner Rajitha a great team person. We are highly indebted to both of them for providing such a personal touch. Their professional team has made sure our went very smoothly.`],
                    smallcontent: [``],
                    author: 'Dilip Meswani', 
                    dis: `Bride's Dad`
                  },
                  {
                    id: 17,
                    content: [`Very polite ,soft spoken& co-operative staff👍`,
                              `Well planned & well executed event management.....`,
                              `We will highly recommend 'Wedding stories' for stress free event`],
                    smallcontent: [``],
                    author: 'Sachin Baxi', 
                    dis: `Client`
                  },
                  {
                    id: 18,
                    content: [
                      `Rajitha & her team bring with them years of invaluable experience in the field of event management. Their expertise and attention to detail ensured that our event was not only successful but also stress-free. They demonstrated great professionalism and were instrumental in coordinating every aspect of the event seamlessly.`,
                      `The entire team at "Wedding Stories" is exceptional and well-coordinated, making the planning process smooth and efficient. Based on our experience, I would highly recommend "Wedding Stories" to anyone seeking professional event planning services. They exceeded our expectations and made our event truly memorable.`,

                    ],
                    smallcontent: [``],
                    author: 'HR Singrodia & LLP ', 
                    dis: `Client `
                  },
                  {
                    id: 19,
                    content: [
                      `Great experience, highly professional and takes care of everything, they host the party like it's their own family. Highly recommended!!`],
                    smallcontent: [``],
                    author: 'Garv Poddar', 
                    dis: `Client `
                  },
                  {
                    id: 20,
                    content: [`We hired them for my Son's wedding. They make sure everlasting stories are witnessed for a wedding they help organise.`,],
                    smallcontent: [``],
                    author: 'Padma Dhote', 
                    dis: `Groom's Mom`
                  },
                  {
                    id: 21,
                    content: [`I feel awesome,mind blowing experience with this team...super co-ordination,Fmly environment and special sir and mam..no word for them...i didn't feel tht I meeting them first time...`],
                    smallcontent: [``],
                    author: 'Joshi Pooja', 
                    dis: `Client`
                  },
                  {
                    id: 22,
                    content: [`We had a wonderful time at our friend's wedding. They made it so easy and stress-free for us so that we could really enjoy the event. It was a beautiful wedding! Thank you!`,],
                    smallcontent: [``],
                    author: 'ptb415', 
                    dis: `Bride's Friend`
                  },
                  {
                    id: 23,
                    content: [`Extremely detail-oriented. Have some amazing suggestions for planning but are executioners of 1st order. Recommend 10/10.`,],
                    smallcontent: [``],
                    author: 'Shivank Dhote', 
                    dis: `Groom `
                  },
                  {
                    id: 24,
                    content: [
                      `Trust 'Wedding Stories', to create blockbuster events..`,
                      `Went to this Communion celebration.`,
                      `Right From the fairy tale Grand entry to the way the Compere got all of them on the dance floor...`,
                      `The definite Cherry on the cake was the Singer...It was all just awesome...`,
                      `'Wedding Stories'.. My next event manager for sure...`
                                       
                    ],
                    smallcontent: [``],
                    author: 'Laxman Karki', 
                    dis: `Client `
                  },
                  {
                    id: 25,
                    content: [`It was nice experience with wedding stories from the start to last.... Entry was awesome with dhol and warm welcome.... Team were very active with all their members 😊😊`,],
                    smallcontent: [``],
                    author: 'Babita Singh ', 
                    dis: `Client `
                  },
                  {
                    id: 26,
                    content: [`It was really awesome team and they arranged like we never get a chance to say negative about the event. Really I recommend them to everyone to the take their support for event which you are planning to organize for  corporates`,],
                    smallcontent: [``],
                    author: 'Raghu A R', 
                    dis: `Client`
                  },
                  {
                    id: 27,
                    content: [
                      `For Our Spartan corporate event`,
                      `We have enjoyed the adventurous activities planned by them for 2 days . Relived the childhood days . Their signature game show Bollywood
Badshah, and the place of stay organized.
`,
                    ],
                    smallcontent: [``],
                    author: 'Bhavesh Pashte', 
                    dis: `Client `
                  },
                  {
                    id: 28,
                    content: [
                      `Very polite ,soft spoken& co-operative staff👍`,
                      `Well planned & well executed event management...`,
                      `We will highly recommend 'Wedding stories' for stress free event.`,
                    
                    ],
                    smallcontent: [``],
                    author: 'Sachin Baxi ', 
                    dis: `Client `
                  },
                  {
                    id: 29,
                    content: [`Rajitha and Jinesh had hosted a games show at our Clubs Shukriya night. All our members have enjoyed the game with camaraderie and competitive spirit . They are enthusiastic and friendly and open to suggestions. Thank you guys.`],
                    smallcontent: [``],
                    author: 'Devika Raheja ', 
                    dis: `Client `
                  },
                  {
                    id: 30,
                    content: [`Superb Adventure activities for our spartan corporate events. Team is so supportive. They are very trustworthy person.`],
                    smallcontent: [``],
                    author: 'Nikhil Sakpal', 
                    dis: `Client `
                  },
                  {
                    id: 31,
                    content: [`Great experience I had with wedding stories. Had a great time with the magician, who kept the kids entertained with all his tricks but the show stopper were his animals, and that also not 1 or 2 but 5 of them. Kids went crazy with all those cute animals. Even elders also had a hearty laugh on all his jokes. The tattoo girl also stole the heart of all the girls, with her very creative and very beautiful tattoos. We just loved the experience, and the organizer was so professional that we did had to worry about anything. Thank u once again, u made my kids birthday very memorable.`],
                    smallcontent: [``],
                    author: 'Shilpa Sharma', 
                    dis: `Client `
                  },
                  {
                    id: 32,
                    content: [`The team was very professional and prompt at service. They came on time, cleaned the mess that was created and came back on time to collect as well. Very beautiful decoration and very neatly done. The professionals also submitted there vaccination certificate before entering in the house and their masked up all the time.. highly recommended`],
                    smallcontent: [``],
                    author: 'Chhavi Kejriwal ', 
                    dis: `Client `
                  }


      ];
  
      const openTestimonial = (id) => {
        setCurrentTestimonial(id);
      };
    
      const closeTestimonial = () => {
        setCurrentTestimonial(null);
      };
    
      const prevTestimonial = () => {
        if (currentTestimonial > 1) {
          setCurrentTestimonial(currentTestimonial - 1);
        }
      };
    
      const nextTestimonial = () => {
        if (currentTestimonial < testimonials.length) {
          setCurrentTestimonial(currentTestimonial + 1);
        }
      };

  return (
<>
        {testimonials.map((testimonial, index) => (
            <Col lg={4} key={index}>
             <div className='testimonialsBG'>
             <Card key={testimonial.id} onClick={() => openTestimonial(testimonial.id)} className='w-100 h-100'>
                          <Card.Body>
                          <Card.Img src={star} className='mb-3'  />
                          <Card.Text className='text-center' >
                          <div className='scroll_swier'>
                          {testimonial.content} 
                          </div>
                        </Card.Text>
                        <Card.Title className='redColor'>  {testimonial.author} 
                        <div className='stylefont text-center mb-0 text-dark'> {testimonial.dis} </div>
                        </Card.Title>
                        <button className='btn viewmore_btn fw-normal p-2 pt-1 pb-1 '>Read More</button>
                      </Card.Body>
                    </Card>
          </div>
          </Col>

        ))}
        
        {currentTestimonial && (
        <Modal show={true} onHide={closeTestimonial} centered  size="lg">
         <div className="card">

         <Modal.Header closeButton>
            <Modal.Title> 
              <div className='d-flex justify-content-start align-items-center'>
              <img src={poupLogo} alt="logo" style={{width: 60}} />
              <img src={star} alt="" style={{ width: 110}} className='ms-3' />
               {/* <h5 className='ms-2 pt-2'>Testimonials -  {` ${currentTestimonial}`} </h5> */}
           
             </div>
             </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {testimonials.find(t => t.id === currentTestimonial).content.map((paragraph, index) => (
            <div key={index}><p>{paragraph}</p> </div>
                       
            ))}
          
          </Modal.Body>
          <Modal.Footer className='d-flex justify-content-between align-items-center'>
          <div>
          <h6 className='redColor'>{testimonials.find(t => t.id === currentTestimonial).author} <span className=' text-dark fw-light stylefont1'>- {testimonials.find(t => t.id === currentTestimonial).dis} </span></h6>
        
          </div>
          
          <div>
          <Button variant="secondary" className='text-white btn me-2' onClick={prevTestimonial} disabled={currentTestimonial === 1}>
              Previous
            </Button>
           
            <Button variant="secondary" className='text-white btn' onClick={nextTestimonial} disabled={currentTestimonial === testimonials.length}>
              Next
            </Button>

          </div>
           
            {/* <Button variant="primary" onClick={closeTestimonial}>
              Close
            </Button> */}
          </Modal.Footer>
         </div>
        </Modal>
      )}
    </>
   
  )
}

export default TestimonialsGrid
