import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { NavLink } from "react-router-dom";

const Videos = () => {
  return (
    <React.Fragment>
              <section className="inner_banner">
  <Container>
      <Row>
        <Col lg={12}>

        <div className="position-absolute top-50 start-50 translate-middle ">
            <h2 className="text-uppercase">Gallery - Videos</h2>
             </div>
          </Col></Row>
          </Container> 
    </section>

    <section>
<Container>
        <Row className='text-center mb-4'> 
        <Col>  
    <NavLink to="/Gallery/Photos"><Button variant="secondary me-3">Photos</Button></NavLink>  
    <NavLink to="/Gallery/Videos"><Button variant="secondary me-3">Videos</Button></NavLink>  

     </Col>      </Row>
</Container>
</section>


<section id='grid'> 
 <Container>
 <div className="row row-cols-lg-4 row-cols-md-2 row-cols-1">

 <div className="col">one Coloumn</div>
 <div className="col">two Coloumn</div>
 <div className="col">Three Coloumn</div>
 <div className="col">Four Coloumn</div>
 <div className="col">Five Coloumn</div>
 <div className="col">Six Coloumn</div>


 </div>
 </Container>

</section>


    </React.Fragment>
  )
}

export default Videos
