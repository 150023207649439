import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { NavLink } from "react-router-dom";
import SliderOne from "../../images/home-slider/Wedding-Planning.jpg";
import SliderTwo from "../../images/home-slider/wedding-entertainment.jpg";
import SliderThree from "../../images/home-slider/cummunity.jpg";
import SliderFour from "../../images/home-slider/milestone.jpg";
import SliderFive from "../../images/home-slider/wedding-grand-entries.jpg";
import SliderSix from "../../images/home-slider/Wedding-Planning.jpg";
import SliderSeven from "../../images/home-slider/offsite-engagement.jpg";
import WPIcon from "../../images/WP-icon.png";
import WEIcon from "../../images/WE-Icon.png";
import DIcon from "../../images/DIcon.png";
import WPImg from "../../images/wedding-planning-01.jpg";
import WEImg from "../../images/weddin-entertainment.jpg";
import WDImg from "../../images/wedding-destination.jpg";
import CommunityEvents from "../../images/CommunityEvents.jpg";
import MilestoneCelebrations from "../../images/Milestone-Celebrations.jpg";
import CEImg from "../../images/corporate-events.jpg";
import CEIcon from "../../images/CE-Icon.png";
import CIcon from "../../images/CEIcon.png";
import MSIcon from '../../images/mc-icon.png'


import HomeCard from '../HomeCard/HomeCard';
import Testimonial from '../Testimonial/Testimonial';
import '../Home/Home.css';



const homecard = [
  {
    id: 1,
    title: "Wedding Planning",
    bannerimg:`${WPImg}`,
      icon:`${WPIcon}`  
  },
  {
    id: 2,
    title: "Wedding Entertainment",
    bannerimg: `${WEImg}`,
    icon:`${WEIcon}`
  },
  {
    id: 3,
    title: "Destination Weddings",
      bannerimg:`${WDImg}`  ,
      icon:`${DIcon}`
  }, 
  {
    id: 4,
    title: "Milestone Celebrations",
      bannerimg:`${MilestoneCelebrations}`  ,
      icon:`${MSIcon}`
  },
  {
    id: 5,
    title: "Community Events",
      bannerimg:`${CommunityEvents}`  ,
      icon:`${CIcon}`
  },
  {
    id: 6,
    title: "Corporate Events",
      bannerimg:`${CEImg}`  ,
      icon:`${CEIcon}`
  },

];


const TestimonialsCard = [
  {
    id: 1,
    title: "Milestone Celebrations",
    bannerimg:`${WPImg}`
  },
  {
    id: 2,
    title: "Milestone Celebrations",
    bannerimg: `${WEImg}`
  },
  {
    id: 3,
    title: "Milestone Celebrations",
      bannerimg:`${CommunityEvents}` 
  }

];

const Home = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
};





  return (
    <>
    <Carousel fade>
      <Carousel.Item>
      <img src={SliderOne} alt=""   className="d-block w-100" />

        <Carousel.Caption>
        <h1 className='text-white'>Wedding Planning</h1>
        <h5 className='text-white'>We Create Weddings That Reflect Your Own Story</h5>
      </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item>
      <img src={SliderTwo} alt=""   className="d-block w-100" />
        <Carousel.Caption>
        <h1 className='text-white'>Wedding Entertainment</h1>
        <h5 className='text-white'>We Have The Pulse Of Wedding Entertainment </h5>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
      <img src={SliderThree} alt=""   className="d-block w-100" />
        <Carousel.Caption>
        <h1 className='text-white'>Community Events</h1>
        <h5 className='text-white'>We Make Community Events An Absolute Success </h5>
          </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
      <img src={SliderFour} alt=""   className="d-block w-100" />
      <Carousel.Caption>
        <h1 className='text-white'>Milestone Celebrations</h1>
        <h5 className='text-white'>We Create Beautiful & Emotionally-Charged Events</h5>
          </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item>
      <img src={SliderFive} alt=""   className="d-block w-100" />
        <Carousel.Caption>
        <h1 className='text-white'>Wedding Grand Entries</h1>
        <h5 className='text-white'>A One Stop Solution For All Your Grand Entries</h5>
          </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item>
      <img src={SliderSix} alt=""   className="d-block w-100" />
        <Carousel.Caption>
          <h1 className='text-white'>Corporate Events</h1>
          <h5 className='text-white'>We Create Phenomenal Event Experiences</h5>
          </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
      <img src={SliderSeven} alt=""   className="d-block w-100" />
        <Carousel.Caption>
        <h1 className='text-white'>Destination Wedding</h1>
        <h5 className='text-white'>You Have Our Exclusive Attention</h5>
        </Carousel.Caption>
      </Carousel.Item>




    </Carousel>


      <section className='about_section_home py-4 '>
      <Container>
      <Row>
        <Col lg={12}>
       
        <h2 className="style01 text-center">From the Founder's Desk</h2>

<p className="">
    Hi, <br />
    I am Jineshh Vaidya, Founder, Wedding Stories. If you are here, it's perhaps because we were
    referred to you or you were searching for a wedding planner or you were checking out our
    profile.
</p>

<p className="">
    In my first interaction with clients, oftentimes i get asked what is your role as a wedding
    planner? What is your scope of work? What would be your fees and what would it include?
    (Hyperlink it to Our Services page)
</p>
<p className="">
    And some funny ones too like this is something which even our family can do, why do we need to
    pay you so much?

</p>
<p className="">

    This is an honest attempt to answer all your questions, so by the end you can consciously
    evaluate if you need a wedding planner.
</p>

<p className="">

    So, let's get you started. Ask yourself these questions ?
</p>
<ol>
    <li> Do I have the time to be involved in every little aspect in my wedding?</li>
    <li> Do I have the requisite experience to handle it all by myself?</li>
    <li>Do I have a family member, friend or relative who can guide me, and whom I can count on for
        the
        wedding process?</li>
    <li>Am I well equipped to handle any last minute hiccups (problems) which might arise?</li>
    <li> Do I wish to involve myself in every little detail of my wedding planning or should I
        offload
        the stress to a professional?</li>

</ol>
<p className="">
    If you ticked off a “No” to at least one of the above, you should strongly consider hiring a
    wedding
    planner. </p>

<p className="">
    And while you are here, you can scan our profile and run over some of our testimonials and
    blogs.
    Give us a call and we will revert back to you soon.
</p>
        
     <div className="pen_img d-none d-md-none d-lg-block"></div>   
      
      
        </Col>
      </Row>
    </Container>



      </section>

      <section className='py-4'>
    <Container>
      <h2 className='text-center mb-2'>Our Services</h2>

    <Row xs={1} md={2} lg={3}>
    <NavLink to="/WeddingPlanning/Wedding-Planning" style={{textDecoration:'none'}}  onClick={scrollToTop}> <HomeCard title={homecard[0].title} bannerimg={homecard[0].bannerimg} icon={homecard[0].icon}/>    </NavLink>
    <NavLink to="/Wedding-Entertainment" style={{textDecoration:'none'}} onClick={scrollToTop}><HomeCard title={homecard[1].title} bannerimg={homecard[1].bannerimg} icon={homecard[1].icon}/>
    </NavLink> 

    <NavLink to="/Destination-Wedding"  onClick={scrollToTop} style={{textDecoration:'none'}}> 
    <HomeCard title={homecard[2].title} bannerimg={homecard[2].bannerimg} icon={homecard[2].icon} />
    </NavLink> 

    <NavLink to="/Milestone-Celebration"  onClick={scrollToTop} style={{textDecoration:'none'}}> 
    <HomeCard title={homecard[3].title} bannerimg={homecard[3].bannerimg} icon={homecard[3].icon}/>
    </NavLink> 

    <NavLink to="/OtherEvents/Community-Events"  onClick={scrollToTop} style={{textDecoration:'none'}}> 
    <HomeCard title={homecard[4].title} bannerimg={homecard[4].bannerimg} icon={homecard[4].icon}/>
    </NavLink> 

    <NavLink to="/OtherEvents/Corporate-Events"  onClick={scrollToTop} style={{textDecoration:'none'}}> 
    <HomeCard title={homecard[5].title} bannerimg={homecard[5].bannerimg} icon={homecard[5].icon}/>
    </NavLink> 

    </Row>
    </Container>



      </section>
  
      <section className='testimonials_bg py-4'>
      <Container>
      <h2 className='text-center mb-2'>Testimonials</h2>

      <Row  xs={1} md={3} lg={3}>
      <Testimonial title={TestimonialsCard[0].title} bannerimg={TestimonialsCard[0].bannerimg} />
      <Testimonial title={TestimonialsCard[1].title} bannerimg={TestimonialsCard[1].bannerimg} />
      <Testimonial title={TestimonialsCard[2].title} bannerimg={TestimonialsCard[2].bannerimg} />
       </Row>

      
       <div className='text-center'>
       <NavLink to="/Testimonials" className='btn viewmore_btn'  onClick={scrollToTop} >View More</NavLink>
        </div>
      </Container>
     </section>


    </>
  )
}

export default Home
