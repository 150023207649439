import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../About/About.css";
import AccordianTab from "./AccordianTab";

const About = () => {
  return (
    <React.Fragment>
      <section className="inner_banner">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="position-absolute top-50 start-50 translate-middle">
                <h4 className="d-block text-center">About Us</h4>
                <h2 className="text-uppercase">WHAT WE DO</h2>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="hlello mb-5">
                <h2 className="text-center">
                  We make your wedding one-of-a-kind
                  <span className="fw-bold text-center d-block">
                    Bespoke | Fun | Budget Optimized
                  </span>
                </h2>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className=" about_section_home mb-5">
        <Container>
          <Row>
            <Col lg="12 py-4">
              <div className="text-center">
                <h2>Wedding Stories</h2>
                <h3>The only Indian wedding planner you need</h3>
                <p>
                  Every marriage is a love story. We ensure it starts well
                  through a personalised wedding. You may be a Marwari, a
                  Punjabi, a Gujarati or a Parsi couple planning a wedding. We
                  have got your back! When it comes to wedding planners in
                  India, look no further. Here’s why!!
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <AccordianTab />
      </section>

      <section className="about_section_home mb-5  py-4 ">
        <Container className="">
          <Row className="text-center  ">
            <Col lg="12 py-4 ">
              <h2>What makes us different?</h2>
            </Col>

            <Col lg="4" className="px-5">
            <div className="pink_circle">
            <span> We optimize <br/>your budget.</span>
            </div>
            </Col>


            <Col lg="4" className="px-5">
            <div className="yellow-circle">
           
            <span>We plan your <br/>
            wedding uniquely</span>
            </div>

                      
            </Col>

            <Col lg="4" className="px-5">
            <div className="green-circle">
            <span>We deliver what  <br/> we promise</span>
           
            </div>
            </Col>
          </Row>
        </Container>
      </section>


      <section className=" mb-5">
        <Container>
          <Row>
            <Col lg="12 py-4">
              <div className="text-center">
                <h2>Now we are ready for the next step...</h2>
               
              </div>
            </Col>
          </Row>
        </Container>
      </section>


    </React.Fragment>
  );
};

export default About;
