import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const OtherEvents = () => {
  return (
    <React.Fragment>
  <section className="inner_banner">
  <Container>
      <Row>
        <Col lg={12}>

        <div className="position-absolute top-50 start-50 translate-middle ">
            <h2 className="text-uppercase">Other Events</h2>
             </div>
          </Col></Row>
          </Container> 
    </section>
    </React.Fragment>

  )
}


export default OtherEvents
