import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../About/About.css";
import AccordianTab from "./AccordianTab";

import pinkcircle from "../../images/pink-circle.png";
import yellowcircle from "../../images/green-circle.png";
import greencircle from "../../images/yellow-circle.png";



const WhatWeDo = () => {
  return (
    <React.Fragment>
      <section className="inner_banner">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="position-absolute top-50 start-50 translate-middle">
                <h4 className="d-block text-center">About Us</h4>
                <h2 className="text-uppercase">WHAT WE DO</h2>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="hlello mb-5 d-flex justify-content-center align-items-center">
                <h2 className="text-center">
                  We make your Wedding One-Of-a-Kind
                  <span className="fw-bold text-center d-block">
                    Bespoke | Fun | Budget Optimized
                  </span>
                </h2>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <AccordianTab />
      </section>

      <section className="about_section_home   py-4 pb-5 ">
        <Container className="">
          <Row className="text-center">
            <Col lg="12 py-4 ">
              <h2>What makes us different?</h2>
            </Col>

            <Col lg="4" md="4" sm="4"  className="">
            <img src={pinkcircle} alt="" className="img-fluid"  />
            </Col>


            <Col lg="4" md="4" sm="4"  className="">
            <img src={greencircle} alt="" className="img-fluid" />
            </Col>

            <Col lg="4" md="4" sm="4"  className="">
            <img src={yellowcircle} alt="" className="img-fluid"/>
            </Col>
          </Row>
        </Container>
      </section>




      <section className="pt-2">
        <Container>
          <Row>
            <Col lg="12 py-3">
              <div className="text-center">
                <h3>The only Indian wedding planner you need</h3>
                <p className="text-center mt-2">
                  Every marriage is a love story. We ensure it starts well
                  through a personalised wedding. You may be a Marwari, a
                  Punjabi, a Gujarati or a Parsi couple planning a wedding. We
                  have got your back! When it comes to wedding planners in
                  India, look no further.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>


    </React.Fragment>
  );
};

export default WhatWeDo;
