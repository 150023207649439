import React, { useState } from 'react';
import './Sitemap.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { GoDotFill, GoDot } from "react-icons/go";
import { NavLink } from "react-router-dom";

const Sitemap = () => {

  const [hoveredLink, setHoveredLink] = useState(null);
  const handleMouseEnter = (link) => {
      setHoveredLink(link);
  };
  const handleMouseLeave = () => {
      setHoveredLink(null);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
<React.Fragment>
<section className="inner_banner">
  <Container>
      <Row>
        <Col lg={12}>
        <div className="position-absolute top-50 start-50 translate-middle">
            <h2 className="text-uppercase">Sitemap</h2>
  </div>
</Col>

          </Row>
          </Container> 
    </section>



<section className=''>
<Container>
<Col lg={6}>

<div id="sitemap">

<div style={styles.mycontainer}>
            <ul style={styles.list}>
                <li style={styles.listItem}>
                    <GoDotFill style={styles.icon} />
                    <NavLink
                        to="/"
                        style={hoveredLink === 'home' ? { ...styles.link, ...styles.linkHover } : styles.link}
                        onMouseEnter={() => handleMouseEnter('home')}
                        onMouseLeave={handleMouseLeave}
                    >
                        Home
                    </NavLink>
                </li>

                <li style={styles.listItem}>
                    <GoDotFill style={styles.icon} />
                    <NavLink to="/About/What-We-Do"
                     style={hoveredLink === 'about' ? { ...styles.link, ...styles.linkHover } : styles.link}
                     onMouseEnter={() => handleMouseEnter('about')}
                     onMouseLeave={handleMouseLeave}
                    onClick={scrollToTop} >About</NavLink>
                   
                    <ul style={styles.subList}>
                    <li style={styles.subListItem}><GoDot style={styles.subIcon} /><NavLink to="/About/What-We-Do" onClick={scrollToTop} 
                     style={hoveredLink === 'What-We-Do' ? { ...styles.link, ...styles.linkHover } : styles.link}
                     onMouseEnter={() => handleMouseEnter('What-We-Do')}
                     onMouseLeave={handleMouseLeave}
                    >Whay We Do</NavLink></li> 
                    <li style={styles.subListItem}><GoDot style={styles.subIcon} /> <NavLink to="/About/Who-We-Are" onClick={scrollToTop} 
                     style={hoveredLink === 'Who-We-Are' ? { ...styles.link, ...styles.linkHover } : styles.link}
                     onMouseEnter={() => handleMouseEnter('Who-We-Are')}
                     onMouseLeave={handleMouseLeave}
                    >Who We Are</NavLink></li> 
                    <li style={styles.subListItem}><GoDot style={styles.subIcon} /> <NavLink to="/About/Our-Logo-Story" onClick={scrollToTop} 
                     style={hoveredLink === 'Our-Logo-Story' ? { ...styles.link, ...styles.linkHover } : styles.link}
                     onMouseEnter={() => handleMouseEnter('Our-Logo-Story')}
                     onMouseLeave={handleMouseLeave}
                    >Our Logo Story</NavLink></li> 
                    </ul>
                </li>



                <li style={styles.listItem}>
                    <GoDotFill style={styles.icon} />
                    <NavLink to="/WeddingPlanning/Wedding-Planning"
                     style={hoveredLink === 'Wedding-Planning' ? { ...styles.link, ...styles.linkHover } : styles.link}
                     onMouseEnter={() => handleMouseEnter('Wedding-Planning')}
                     onMouseLeave={handleMouseLeave}
                    onClick={scrollToTop} >Wedding Planning</NavLink>
                   
                    <ul style={styles.subList}>
                    <li style={styles.subListItem}><GoDot style={styles.subIcon} /><NavLink to="/WeddingPlanning/Wedding-Planning" onClick={scrollToTop} 
                     style={hoveredLink === 'Wedding-Planning' ? { ...styles.link, ...styles.linkHover } : styles.link}
                     onMouseEnter={() => handleMouseEnter('Wedding-Planning')}
                     onMouseLeave={handleMouseLeave}
                    >Ou Services</NavLink></li> 
                  
                    <li style={styles.subListItem}><GoDot style={styles.subIcon} /> <NavLink to="/WeddingPlanning/Why-Us" onClick={scrollToTop} 
                     style={hoveredLink === 'Why-Us' ? { ...styles.link, ...styles.linkHover } : styles.link}
                     onMouseEnter={() => handleMouseEnter('Why-Us')}
                     onMouseLeave={handleMouseLeave}
                    >Why Us</NavLink></li> 
         
                    </ul>
                </li>



                <li style={styles.listItem}>
                    <GoDotFill style={styles.icon} />
                    <NavLink
                        to="/Wedding-Entertainment"
                        style={hoveredLink === 'Wedding-Entertainment' ? { ...styles.link, ...styles.linkHover } : styles.link}
                        onMouseEnter={() => handleMouseEnter('Wedding-Entertainment')}
                        onMouseLeave={handleMouseLeave}
                    >
                       Wedding Entertainment
                    </NavLink>
                </li>


                <li style={styles.listItem}>
                    <GoDotFill style={styles.icon} />
                    <NavLink
                        to="/Destination-Wedding"
                        style={hoveredLink === 'Destination-Wedding' ? { ...styles.link, ...styles.linkHover } : styles.link}
                        onMouseEnter={() => handleMouseEnter('Destination-Wedding')}
                        onMouseLeave={handleMouseLeave}
                    >
                     Destination Wedding
                    </NavLink>
                </li>


                <li style={styles.listItem}>
                    <GoDotFill style={styles.icon} />
                    <NavLink
                        to="/Milestone-Celebration"
                        style={hoveredLink === 'Milestone-Celebration' ? { ...styles.link, ...styles.linkHover } : styles.link}
                        onMouseEnter={() => handleMouseEnter('Milestone-Celebration')}
                        onMouseLeave={handleMouseLeave}
                    >
                    Milestone Celebration
                    </NavLink>
                </li>


        
            </ul>
        </div>


</div>

</Col>


</Container>
</section>



</React.Fragment>

  )
}

const styles = {
  mycontainer: {
    color: '#333',
    padding: '20px',
},
myheader: {
    color: '#4a4a4a',
    textAlign: 'center',
    marginBottom: '30px',
},
list: {
    listStyleType: 'none',
    paddingLeft: '0',
},
listItem: {
    margin: '10px 0',
},
subList: {
    listStyleType: 'none',
    paddingLeft: '20px', /* Indent sub-links */
    marginTop: '5px',
},
subListItem: {
    margin: '5px 0',
},

link: {
    textDecoration: 'none',
    color: '#777777 ',
    fontSize: '18px',
    paddingLeft: '5px',
    verticalAlign: 'middle',
},
icon: {
    color: '#777777 ',
    fontSize: '14px',
    verticalAlign: 'middle',
},
subIcon: {
    color: '#777777 ',
    fontSize: '14px',
    verticalAlign: 'middle',
},
linkHover: {
  color: '#ed1651',
},
};

export default Sitemap


