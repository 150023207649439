import React from 'react'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from './Tab';

import TheFamilTrerr from '../../images/The-Tree-Family.jpg';
import RootsCouple from '../../images/roots-couple.png';

const OurLogoStory = () => {
  return (
    <React.Fragment>
           <section className="inner_banner">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="position-absolute top-50 start-50 translate-middle">
                <h4 className="d-block text-center">About Us</h4>
                <h2 className="text-uppercase">Our Logo Story</h2>

              </div>
            </Col>
          </Row>
        </Container>
      </section> 


      <section className="pitch_bg mb-5">
        <Container>
          <Row>
            <Col lg="12 py-4">
              <div className="text-center">
          
                <h2>What Inspired Wedding Stories?</h2>
                
                <p className='text-center'>
                Wedding Stories is a culmination of our deep respect ,love and vigor for Indian Weddings.
               </p>

               <p className='text-center'>
               When we conceived Wedding Stories, we knew that couples dream of making their wedding memorable in every way. So we wanted to be there for them, standing alongside their dreams to execute each of their ideas. But most importantly, we wanted their wedding to represent their own true personality.


               </p>

               <p className='text-center'>
               The story of Wedding Stories is best told in our Brand Logo. It was designed thoughtfully considering each element of an Indian couple's life and each element of an Indian wedding.


               </p>

              </div>
            </Col>
          </Row>
        </Container>
      </section>


      <section className=" mb-5">
        <Container>
          <Row>
            <Col lg="12 py-2">
           
              <div className='d-flex flex-column flex-lg-row flex-lg-row align-items-start align-items-lg-center'>
              <img src={TheFamilTrerr} alt="TheFamilTrerr" className='me-3' />
              <div>
              <h2>The Tree: Family</h2>
              <p className=''>
                Our logo represents a tree: the family tree that has so many    members who bring in their own unique personality to the table. The tree represents our deep desire to honor each of your family members while planning the wedding.
                </p>
              </div>

              </div>
               


      
            </Col>
            </Row>
            </Container>
            </section>


            <section className="pitch_bg mb-5">
        <Container>
          <Row>
            <Col lg="12 py-4">


            <div className='d-flex flex-column flex-lg-row flex-lg-row align-items-start align-items-lg-center'>
              <img src={RootsCouple} alt="TheFamilTrerr" className='me-3 ' />
              <div>
              <h2>The Entwining Roots: The Couple</h2>
              <p className=''>
              Marriage is the entwining of two lives, two souls. Together these two lives make a couple. Our logo represents this idea by the two entwined roots: the man (dark brown) and the woman (light brown). These two strong roots make for a strong long-lasting family tree. For us the entwining roots represent the combining of two personalities: their likes, dislikes and preferences in their wedding.
              </p>
              </div>

              </div>





            </Col>
          </Row>
        </Container>
      </section>

    <section>
    <Tab/>


    </section>


    </React.Fragment>
  )
}

export default OurLogoStory
