import haladi_01 from '../images/photos/suvidhi_rishabh/haladi_01.jpg'
import haladi_02 from '../images/photos/suvidhi_rishabh/haladi_02.jpg'
import haladi_03 from '../images/photos/suvidhi_rishabh/haladi_03.jpg'
import haladi_04 from '../images/photos/suvidhi_rishabh/haladi_04.jpg'
import haladi_05 from '../images/photos/suvidhi_rishabh/haladi_05.jpg'
import haladi_06 from '../images/photos/suvidhi_rishabh/haladi_06.jpg'
import haladi_07 from '../images/photos/suvidhi_rishabh/haladi_07.jpg'
import haladi_08 from '../images/photos/suvidhi_rishabh/haladi_08.jpg'
import haladi_09 from '../images/photos/suvidhi_rishabh/haladi_09.jpg'
import haladi_10 from '../images/photos/suvidhi_rishabh/haladi_10.jpg'
 import haladi_11 from '../images/photos/suvidhi_rishabh/haladi_11.jpg'

import reception_01 from '../images/photos/suvidhi_rishabh/reception_01.jpg'
import reception_02 from '../images/photos/suvidhi_rishabh/reception_02.jpg'
import reception_03 from '../images/photos/suvidhi_rishabh/reception_03.jpg'
import reception_04 from '../images/photos/suvidhi_rishabh/reception_04.jpg'
import reception_05 from '../images/photos/suvidhi_rishabh/reception_05.jpg'
import reception_06 from '../images/photos/suvidhi_rishabh/reception_06.jpg'
import reception_07 from '../images/photos/suvidhi_rishabh/reception_07.jpg'
import reception_08 from '../images/photos/suvidhi_rishabh/reception_08.jpg'


import wedding_01 from '../images/photos/suvidhi_rishabh/wedding-01.jpg'
import wedding_02 from '../images/photos/suvidhi_rishabh/wedding-02.jpg'
import wedding_03 from '../images/photos/suvidhi_rishabh/wedding-03.jpg'
import wedding_04 from '../images/photos/suvidhi_rishabh/wedding-04.jpg'
import wedding_05 from '../images/photos/suvidhi_rishabh/wedding-05.jpg'
import wedding_06 from '../images/photos/suvidhi_rishabh/wedding-06.jpg'
import wedding_07 from '../images/photos/suvidhi_rishabh/wedding-07.jpg'
import wedding_08 from '../images/photos/suvidhi_rishabh/wedding-08.jpg'
import wedding_09 from '../images/photos/suvidhi_rishabh/wedding-09.jpg'
import wedding_10 from '../images/photos/suvidhi_rishabh/wedding-10.jpg'
import wedding_11 from '../images/photos/suvidhi_rishabh/wedding-11.jpg'
import wedding_12 from '../images/photos/suvidhi_rishabh/wedding-12.jpg'
import wedding_13 from '../images/photos/suvidhi_rishabh/wedding-13.jpg'
import wedding_14 from '../images/photos/suvidhi_rishabh/wedding-14.jpg'
import wedding_15 from '../images/photos/suvidhi_rishabh/wedding-15.jpg'
import wedding_16 from '../images/photos/suvidhi_rishabh/wedding-16.jpg'
import wedding_17 from '../images/photos/suvidhi_rishabh/wedding-17.jpg'
import wedding_18 from '../images/photos/suvidhi_rishabh/wedding-18.jpg'

import rn_01 from '../images/photos/rohan_neelam/rn-01.jpg'
import rn_02 from '../images/photos/rohan_neelam/rn-02.jpg'
import rn_03 from '../images/photos/rohan_neelam/rn-03.jpg'
import rn_04 from '../images/photos/rohan_neelam/rn-04.jpg'
import rn_05 from '../images/photos/rohan_neelam/rn-05.jpg'
import rn_06 from '../images/photos/rohan_neelam/rn-06.jpg'
import rn_07 from '../images/photos/rohan_neelam/rn-07.jpg'
import rn_08 from '../images/photos/rohan_neelam/rn-08.jpg'
import rn_09 from '../images/photos/rohan_neelam/rn-09.jpg'
import rn_10 from '../images/photos/rohan_neelam/rn-10.jpg'
import rn_11 from '../images/photos/rohan_neelam/rn-11.jpg'
import rn_12 from '../images/photos/rohan_neelam/rn-12.jpg'
import rn_13 from '../images/photos/rohan_neelam/rn-13.jpg'
import rn_14 from '../images/photos/rohan_neelam/rn-14.jpg'
import rn_15 from '../images/photos/rohan_neelam/rn-15.jpg'
import rn_16 from '../images/photos/rohan_neelam/rn-16.jpg'
import rn_17 from '../images/photos/rohan_neelam/rn-17.jpg'
import rn_18 from '../images/photos/rohan_neelam/rn-18.jpg'
import rn_19 from '../images/photos/rohan_neelam/rn-19.jpg'
import rn_20 from '../images/photos/rohan_neelam/rn-20.jpg'
import rn_21 from '../images/photos/rohan_neelam/rn-21.jpg'
import rn_22 from '../images/photos/rohan_neelam/rn-22.jpg'
import rn_23 from '../images/photos/rohan_neelam/rn-23.jpg'
import rn_24 from '../images/photos/rohan_neelam/rn-24.jpg'
import rn_25 from '../images/photos/rohan_neelam/rn-25.jpg'
import rn_26 from '../images/photos/rohan_neelam/rn-26.jpg'
import rn_27 from '../images/photos/rohan_neelam/rn-27.jpg'
import rn_28 from '../images/photos/rohan_neelam/rn-28.jpg'
import rn_29 from '../images/photos/rohan_neelam/rn-29.jpg'
import rn_30 from '../images/photos/rohan_neelam/rn-30.jpg'
import rn_31 from '../images/photos/rohan_neelam/rn-31.jpg'
import rn_32 from '../images/photos/rohan_neelam/rn-32.jpg'
import rn_33 from '../images/photos/rohan_neelam/rn-33.jpg'

import rn_35 from '../images/photos/rohan_neelam/rn-35.jpg'
import rn_36 from '../images/photos/rohan_neelam/rn-36.jpg'
import rn_37 from '../images/photos/rohan_neelam/rn-37.jpg'
import rn_38 from '../images/photos/rohan_neelam/rn-38.jpg'
import rn_39 from '../images/photos/rohan_neelam/rn-39.jpg'
import rn_40 from '../images/photos/rohan_neelam/rn-40.jpg'
import rn_41 from '../images/photos/rohan_neelam/rn-41.jpg'


import ma_411 from '../images/photos/meghna_arun/BZ9A7166.jpg'
import ma_42 from '../images/photos/meghna_arun/BZ9A7169.jpg'
import ma_43 from '../images/photos/meghna_arun/BZ9A7172.jpg'
import ma_44 from '../images/photos/meghna_arun/BZ9A7181.jpg'
import ma_45 from '../images/photos/meghna_arun/BZ9A7196.jpg'
import ma_46 from '../images/photos/meghna_arun/BZ9A7203.jpg'
import ma_47 from '../images/photos/meghna_arun/BZ9A7231.jpg'
import ma_48 from '../images/photos/meghna_arun/BZ9A7273.jpg'
import ma_49 from '../images/photos/meghna_arun/BZ9A7276.jpg'
import ma_50 from '../images/photos/meghna_arun/BZ9A7325.jpg'
import ma_51 from '../images/photos/meghna_arun/BZ9A7351.jpg'
import ma_52 from '../images/photos/meghna_arun/BZ9A7356.jpg'
import ma_53 from '../images/photos/meghna_arun/BZ9A7702.jpg'
import ma_54 from '../images/photos/meghna_arun/BZ9A7865.jpg'
import ma_55 from '../images/photos/meghna_arun/BZ9A7882.jpg'
import ma_56 from '../images/photos/meghna_arun/BZ9A7907.jpg'
import ma_57 from '../images/photos/meghna_arun/LEN00093.jpg'
import ma_58 from '../images/photos/meghna_arun/LEN00279.jpg'
import ma_59 from '../images/photos/meghna_arun/LEN00605.jpg'
import ma_60 from '../images/photos/meghna_arun/LEN00686.jpg'
import ma_61 from '../images/photos/meghna_arun/LEN04477.jpg'
import ma_62 from '../images/photos/meghna_arun/LEN04922.jpg'
import ma_63 from '../images/photos/meghna_arun/LEN05016.jpg'
import ma_64 from '../images/photos/meghna_arun/LEN05173.jpg'
import ma_65 from '../images/photos/meghna_arun/LEN05180.jpg'



import shalu_reza_01 from '../images/photos/shalu_reza/IMG-1.jpg'
import shalu_reza_02 from '../images/photos/shalu_reza/IMG-4.jpg'
import shalu_reza_03 from '../images/photos/shalu_reza/IMG-11.jpg'
import shalu_reza_04 from '../images/photos/shalu_reza/IMG-21.jpg'
import shalu_reza_05 from '../images/photos/shalu_reza/IMG-25.jpg'
import shalu_reza_06 from '../images/photos/shalu_reza/IMG-29.jpg'
import shalu_reza_07 from '../images/photos/shalu_reza/IMG-36.jpg'
import shalu_reza_08 from '../images/photos/shalu_reza/IMG-40.jpg'
import shalu_reza_09 from '../images/photos/shalu_reza/IMG-53.jpg'
import shalu_reza_10 from '../images/photos/shalu_reza/IMG-54.jpg'
import shalu_reza_11 from '../images/photos/shalu_reza/IMG-57.jpg'
import shalu_reza_12 from '../images/photos/shalu_reza/IMG-61.jpg'
import shalu_reza_13 from '../images/photos/shalu_reza/IMG-64.jpg'
import shalu_reza_14 from '../images/photos/shalu_reza/IMG-68.jpg'
import shalu_reza_15 from '../images/photos/shalu_reza/IMG-69.jpg'
import shalu_reza_16 from '../images/photos/shalu_reza/IMG-72.jpg'
import shalu_reza_17 from '../images/photos/shalu_reza/IMG-76.jpg'
import shalu_reza_18 from '../images/photos/shalu_reza/IMG-90.jpg'
import shalu_reza_19 from '../images/photos/shalu_reza/IMG-94.jpg'
import shalu_reza_20 from '../images/photos/shalu_reza/IMG-95.jpg'
import shalu_reza_21 from '../images/photos/shalu_reza/IMG-97.jpg'
import shalu_reza_22 from '../images/photos/shalu_reza/IMG-98.jpg'
import shalu_reza_23 from '../images/photos/shalu_reza/IMG-103.jpg'
import shalu_reza_24 from '../images/photos/shalu_reza/IMG-105.jpg'
import shalu_reza_25 from '../images/photos/shalu_reza/IMG-112.jpg'
import shalu_reza_26 from '../images/photos/shalu_reza/IMG-139.jpg'
import shalu_reza_27 from '../images/photos/shalu_reza/IMG-192.jpg'
import shalu_reza_28 from '../images/photos/shalu_reza/IMG-199.jpg'
import shalu_reza_29 from '../images/photos/shalu_reza/IMG-207.jpg'
import shalu_reza_30 from '../images/photos/shalu_reza/IMG-215.jpg'
import shalu_reza_31 from '../images/photos/shalu_reza/IMG-220.jpg'
import shalu_reza_32 from '../images/photos/shalu_reza/IMG-264.jpg'
import shalu_reza_33 from '../images/photos/shalu_reza/IMG-351.jpg'


import jenifer_nikcet_01 from '../images/photos/jenifer_nikcet/PK404016.jpg'
import jenifer_nikcet_02 from '../images/photos/jenifer_nikcet/PK404021.jpg'
import jenifer_nikcet_03 from '../images/photos/jenifer_nikcet/PK404030.jpg'
import jenifer_nikcet_04 from '../images/photos/jenifer_nikcet/PK404040.jpg'
import jenifer_nikcet_05 from '../images/photos/jenifer_nikcet/PK404044.jpg'
import jenifer_nikcet_06 from '../images/photos/jenifer_nikcet/PK404067.jpg'
import jenifer_nikcet_07 from '../images/photos/jenifer_nikcet/PK404103.jpg'
import jenifer_nikcet_08 from '../images/photos/jenifer_nikcet/PK404153.jpg'
import jenifer_nikcet_09 from '../images/photos/jenifer_nikcet/PK404213.jpg'
import jenifer_nikcet_10 from '../images/photos/jenifer_nikcet/PK404301.jpg'
import jenifer_nikcet_11 from '../images/photos/jenifer_nikcet/PK404326.jpg'
import jenifer_nikcet_12 from '../images/photos/jenifer_nikcet/PK404338.jpg'
import jenifer_nikcet_13 from '../images/photos/jenifer_nikcet/PK404365.jpg'
import jenifer_nikcet_14 from '../images/photos/jenifer_nikcet/PK404371.jpg'
import jenifer_nikcet_15 from '../images/photos/jenifer_nikcet/PK404436.jpg'
import jenifer_nikcet_16 from '../images/photos/jenifer_nikcet/PK404458.jpg'
import jenifer_nikcet_17 from '../images/photos/jenifer_nikcet/PP400991.jpg'
import jenifer_nikcet_18 from '../images/photos/jenifer_nikcet/PPP06296.jpg'
import jenifer_nikcet_19 from '../images/photos/jenifer_nikcet/PPP06345.jpg'
import jenifer_nikcet_20 from '../images/photos/jenifer_nikcet/PPP06365.jpg'












let data_photos = [
    {      id:1,name:"Suvidhi & Rishabh - Haldi 1",image:haladi_01,details:""},
    {        id:2,name:"Suvidhi & Rishabh - Haldi 2",image:haladi_02,details:""},
      {
        id:3,
        name:"Suvidhi & Rishabh - Haldi 3",
        image:haladi_03,
        details:"",
      },
      {
        id:4,
        name:"Suvidhi & Rishabh - Haldi 4",
        image:haladi_04,
        details:"",
      },
      {
        id:5,
        name:"Suvidhi & Rishabh - Haldi 5",
        image:haladi_05,
        details:"",
      },
      {
        id:6,
        name:"Suvidhi & Rishabh - Haldi 6",
        image:haladi_06,
        details:"",
      },
      {
        id:7,
        name:"Suvidhi & Rishabh - Haldi 7",
        image:haladi_07,
        details:"",
      },
      {
        id:8,
        name:"Suvidhi & Rishabh - Haldi 8",
        image:haladi_08,
        details:"",
      },
      {
        id:9,
        name:"Suvidhi & Rishabh - Haldi 9",
        image:haladi_09,
        details:"",
      },
      {
        id:10,
        name:"Suvidhi & Rishabh - Haldi 10",
        image:haladi_10,
        details:"",
      },
      {
        id:11,
        name:"Suvidhi & Rishabh - Haldi 11",
        image:haladi_11,
        details:"",
      },
    
      {
        id:12,
        name:"Suvidhi & Rishabh - Reception 12",
        image:reception_01,
        details:"",
      },
      {
        id:13,
        name:"Suvidhi & Rishabh - Reception 13",
        image:reception_02,
        details:"",
      },
      {
        id:14,
        name:"Suvidhi & Rishabh - Reception 14",
        image:reception_03,
        details:"",
      },
      {
        id:15,
        name:"Suvidhi & Rishabh - Reception 15",
        image:reception_04,
        details:"",
      },
      {
        id:16,
        name:"Suvidhi & Rishabh - Reception 16",
        image:reception_05,
        details:"",
      },
      {
        id:17,
        name:"Suvidhi & Rishabh - Reception 17",
        image:reception_06,
        details:"",
      },
      {
        id:18,
        name:"Suvidhi & Rishabh - Reception 18",
        image:reception_07,
        details:"",
      },
      {
        id:18,
        name:"Suvidhi & Rishabh - Reception 19",
        image:reception_08,
        details:"",
      },
      {id:18, name:"Suvidhi & Rishabh - Wedding 20", image:wedding_01, details:""},
      {id:19, name:"Suvidhi & Rishabh - Wedding 21", image:wedding_02, details:""},
      {id:20, name:"Suvidhi & Rishabh - Wedding 22", image:wedding_03, details:""},
      {id:21, name:"Suvidhi & Rishabh - Wedding 23", image:wedding_04, details:""},
      {id:22, name:"Suvidhi & Rishabh - Wedding 24", image:wedding_05, details:""},
      {id:23, name:"Suvidhi & Rishabh - Wedding 25", image:wedding_06, details:""},
      {id:24, name:"Suvidhi & Rishabh - Wedding 26", image:wedding_07, details:""},
      {id:25, name:"Suvidhi & Rishabh - Wedding 27", image:wedding_08, details:""},
      {id:26, name:"Suvidhi & Rishabh - Wedding 28", image:wedding_09, details:""},
      {id:27, name:"Suvidhi & Rishabh - Wedding 29", image:wedding_10, details:""},
      {id:28, name:"Suvidhi & Rishabh - Wedding 30", image:wedding_11, details:""},
      {id:29, name:"Suvidhi & Rishabh - Wedding 31", image:wedding_12, details:""},
      {id:30, name:"Suvidhi & Rishabh - Wedding 32", image:wedding_13, details:""},
      {id:31, name:"Suvidhi & Rishabh - Wedding 33", image:wedding_14, details:""},
      {id:32, name:"Suvidhi & Rishabh - Wedding 34", image:wedding_15, details:""},
      {id:33, name:"Suvidhi & Rishabh - Wedding 35", image:wedding_16, details:""},
      {id:34, name:"Suvidhi & Rishabh - Wedding 36", image:wedding_17, details:""},
      {id:35, name:"Suvidhi & Rishabh - Wedding 37", image:wedding_18, details:""},

      {id:36, name:"Rohan & Neelam - Wedding 38", image:rn_01, details:""},
      {id:37, name:"Rohan & Neelam - Wedding 39", image:rn_02, details:""},
      {id:38, name:"Rohan & Neelam - Wedding 40", image:rn_03, details:""},
      {id:39, name:"Rohan & Neelam - Wedding 41", image:rn_04, details:""},
      {id:40, name:"Rohan & Neelam - Wedding 42", image:rn_05, details:""},

      {id:41, name:"Rohan & Neelam - Wedding 43", image:rn_06, details:""},
      {id:42, name:"Rohan & Neelam - Wedding 44", image:rn_07, details:""},
      {id:43, name:"Rohan & Neelam - Wedding 45", image:rn_08, details:""},
      {id:44, name:"Rohan & Neelam - Wedding 46", image:rn_09, details:""},
      {id:45, name:"Rohan & Neelam - Wedding 47", image:rn_10, details:""},
      {id:46, name:"Rohan & Neelam - Wedding 48", image:rn_11, details:""},
      {id:47, name:"Rohan & Neelam - Wedding 49", image:rn_12, details:""},
      {id:48, name:"Rohan & Neelam - Wedding 50", image:rn_13, details:""},
      {id:49, name:"Rohan & Neelam - Wedding 51", image:rn_14, details:""},
      {id:50, name:"Rohan & Neelam - Wedding 52", image:rn_15, details:""},
      
      {id:51, name:"Rohan & Neelam - Wedding 53", image:rn_16, details:""},
      {id:52, name:"Rohan & Neelam - Wedding 54", image:rn_17, details:""},
      {id:53, name:"Rohan & Neelam - Wedding 55", image:rn_18, details:""},
      {id:54, name:"Rohan & Neelam - Wedding 56", image:rn_19, details:""},
      {id:55, name:"Rohan & Neelam - Wedding 57", image:rn_20, details:""},
      {id:56, name:"Rohan & Neelam - Wedding 58", image:rn_21, details:""},
      {id:57, name:"Rohan & Neelam - Wedding 59", image:rn_22, details:""},
      {id:58, name:"Rohan & Neelam - Wedding 60", image:rn_23, details:""},
      {id:59, name:"Rohan & Neelam - Wedding 61", image:rn_24, details:""},
      {id:60, name:"Rohan & Neelam - Wedding 62", image:rn_25, details:""},

      {id:61, name:"Rohan & Neelam - Wedding 63", image:rn_26, details:""},
      {id:62, name:"Rohan & Neelam - Wedding 64", image:rn_27, details:""},
      {id:63, name:"Rohan & Neelam - Wedding 65", image:rn_28, details:""},
      {id:64, name:"Rohan & Neelam - Wedding 66", image:rn_29, details:""},
      {id:65, name:"Rohan & Neelam - Wedding 67", image:rn_30, details:""},
      {id:66, name:"Rohan & Neelam - Wedding 68", image:rn_31, details:""},
      {id:67, name:"Rohan & Neelam - Wedding 69", image:rn_32, details:""},
      {id:68, name:"Rohan & Neelam - Wedding 70", image:rn_33, details:""},
      {id:70, name:"Rohan & Neelam - Wedding 71", image:rn_35, details:""},
      {id:71, name:"Rohan & Neelam - Wedding 72", image:rn_36, details:""},
      {id:72, name:"Rohan & Neelam - Wedding 73", image:rn_37, details:""},
      {id:73, name:"Rohan & Neelam - Wedding 74", image:rn_38, details:""},
      {id:74, name:"Rohan & Neelam - Wedding 75", image:rn_39, details:""},
      {id:75, name:"Rohan & Neelam - Wedding 76", image:rn_40, details:""},
      {id:76, name:"Rohan & Neelam - Wedding 77", image:rn_41, details:""},
      {id:77, name:"Meghna & Arun - Wedding 78", image:ma_411, details:""},
      {id:78, name:"Meghna & Arun - Wedding 79", image:ma_42, details:""},
      {id:79, name:"Meghna & Arun - Wedding 80", image:ma_43, details:""},
      {id:80, name:"Meghna & Arun - Wedding 81", image:ma_44, details:""},
      {id:81, name:"Meghna & Arun - Wedding 82", image:ma_45, details:""},
      {id:82, name:"Meghna & Arun - Wedding 83", image:ma_46, details:""},
      {id:83, name:"Meghna & Arun - Wedding 84", image:ma_47, details:""},
      {id:84, name:"Meghna & Arun - Wedding 85", image:ma_48, details:""},
      {id:85, name:"Meghna & Arun - Wedding 86", image:ma_49, details:""},
      {id:86, name:"Meghna & Arun - Wedding 87", image:ma_50, details:""},
      {id:87, name:"Meghna & Arun - Wedding 88", image:ma_51, details:""},
      {id:88, name:"Meghna & Arun - Wedding 89", image:ma_52, details:""},
      {id:89, name:"Meghna & Arun - Wedding 90", image:ma_53, details:""},
      {id:90, name:"Meghna & Arun - Wedding 91", image:ma_54, details:""},
      {id:91, name:"Meghna & Arun - Wedding 92", image:ma_55, details:""},
      {id:92, name:"Meghna & Arun - Wedding 93", image:ma_56, details:""},
      {id:93, name:"Meghna & Arun - Wedding 94", image:ma_57, details:""},
      {id:94, name:"Meghna & Arun - Wedding 95", image:ma_58, details:""},
      {id:95, name:"Meghna & Arun - Wedding 96", image:ma_59, details:""},
      {id:96, name:"Meghna & Arun - Wedding 97", image:ma_60, details:""},
      {id:97, name:"Meghna & Arun - Wedding 98", image:ma_61, details:""},
      {id:98, name:"Meghna & Arun - Wedding 99", image:ma_62, details:""},
      {id:99, name:"Meghna & Arun - Wedding 100", image:ma_63, details:""},
      {id:100, name:"Meghna & Arun - Wedding 101", image:ma_64, details:""},
      {id:101, name:"Meghna & Arun - Wedding 102", image:ma_65, details:""},



      {id:102, name:"Shalu & Reza - Wedding 103", image:shalu_reza_01, details:""},
      {id:103, name:"Shalu & Reza - Wedding 104", image:shalu_reza_02, details:""},
      {id:104, name:"Shalu & Reza - Wedding 105", image:shalu_reza_03, details:""},
      {id:105, name:"Shalu & Reza - Wedding 106", image:shalu_reza_04, details:""},
      {id:106, name:"Shalu & Reza - Wedding 107", image:shalu_reza_05, details:""},
      {id:107, name:"Shalu & Reza - Wedding 108", image:shalu_reza_06, details:""},
      {id:108, name:"Shalu & Reza - Wedding 109", image:shalu_reza_07, details:""},
      {id:109, name:"Shalu & Reza - Wedding 110", image:shalu_reza_08, details:""},
      {id:110, name:"Shalu & Reza - Wedding 111", image:shalu_reza_09, details:""},
      {id:111, name:"Shalu & Reza - Wedding 112", image:shalu_reza_10, details:""},
      {id:112, name:"Shalu & Reza - Wedding 113", image:shalu_reza_11, details:""},
      {id:113, name:"Shalu & Reza - Wedding 114", image:shalu_reza_12, details:""},
      {id:114, name:"Shalu & Reza - Wedding 115", image:shalu_reza_13, details:""},
      {id:115, name:"Shalu & Reza - Wedding 116", image:shalu_reza_14, details:""},
      {id:116, name:"Shalu & Reza - Wedding 117", image:shalu_reza_15, details:""},
      {id:117, name:"Shalu & Reza - Wedding 118", image:shalu_reza_16, details:""},
      {id:118, name:"Shalu & Reza - Wedding 119", image:shalu_reza_17, details:""},
      {id:119, name:"Shalu & Reza - Wedding 120", image:shalu_reza_18, details:""},
      {id:120, name:"Shalu & Reza - Wedding 121", image:shalu_reza_19, details:""},
      {id:121, name:"Shalu & Reza - Wedding 122", image:shalu_reza_20, details:""},
      {id:122, name:"Shalu & Reza - Wedding 123", image:shalu_reza_21, details:""},
      {id:123, name:"Shalu & Reza - Wedding 124", image:shalu_reza_22, details:""},
      {id:124, name:"Shalu & Reza - Wedding 125", image:shalu_reza_23, details:""},
      {id:125, name:"Shalu & Reza - Wedding 126", image:shalu_reza_24, details:""},
      {id:126, name:"Shalu & Reza - Wedding 127", image:shalu_reza_25, details:""},
      {id:127, name:"Shalu & Reza - Wedding 128", image:shalu_reza_26, details:""},
      {id:128, name:"Shalu & Reza - Wedding 129", image:shalu_reza_27, details:""},
      {id:129, name:"Shalu & Reza - Wedding 130", image:shalu_reza_28, details:""},
      {id:130, name:"Shalu & Reza - Wedding 131", image:shalu_reza_29, details:""},
      {id:131, name:"Shalu & Reza - Wedding 132", image:shalu_reza_30, details:""},
      {id:132, name:"Shalu & Reza - Wedding 133", image:shalu_reza_31, details:""},
      {id:133, name:"Shalu & Reza - Wedding 134", image:shalu_reza_32, details:""},
      {id:134, name:"Shalu & Reza - Wedding 135", image:shalu_reza_33, details:""},
 
      {id:135, name:"Jenifer & Nikcet - Wedding 136", image:jenifer_nikcet_01, details:""},
      {id:136, name:"Jenifer & Nikcet - Wedding 137", image:jenifer_nikcet_02, details:""},
      {id:137, name:"Jenifer & Nikcet - Wedding 138", image:jenifer_nikcet_03, details:""},
      {id:138, name:"Jenifer & Nikcet - Wedding 139", image:jenifer_nikcet_04, details:""},
      {id:139, name:"Jenifer & Nikcet - Wedding 140", image:jenifer_nikcet_05, details:""},
      {id:140, name:"Jenifer & Nikcet - Wedding 141", image:jenifer_nikcet_06, details:""},
      {id:141, name:"Jenifer & Nikcet - Wedding 142", image:jenifer_nikcet_07, details:""},
      {id:142, name:"Jenifer & Nikcet - Wedding 143", image:jenifer_nikcet_08, details:""},
      {id:143, name:"Jenifer & Nikcet - Wedding 144", image:jenifer_nikcet_09, details:""},
      {id:144, name:"Jenifer & Nikcet - Wedding 145", image:jenifer_nikcet_10, details:""},
      {id:145, name:"Jenifer & Nikcet - Wedding 146", image:jenifer_nikcet_11, details:""},
      {id:146, name:"Jenifer & Nikcet - Wedding 147", image:jenifer_nikcet_12, details:""},
      {id:147, name:"Jenifer & Nikcet - Wedding 148", image:jenifer_nikcet_13, details:""},
      {id:148, name:"Jenifer & Nikcet - Wedding 149", image:jenifer_nikcet_14, details:""},
      {id:149, name:"Jenifer & Nikcet - Wedding 150", image:jenifer_nikcet_15, details:""},
      {id:150, name:"Jenifer & Nikcet - Wedding 151", image:jenifer_nikcet_16, details:""},
      {id:151, name:"Jenifer & Nikcet - Wedding 152", image:jenifer_nikcet_17, details:""},
      {id:152, name:"Jenifer & Nikcet - Wedding 153", image:jenifer_nikcet_18, details:""},
      {id:153, name:"Jenifer & Nikcet - Wedding 154", image:jenifer_nikcet_19, details:""},
      {id:154, name:"Jenifer & Nikcet - Wedding 155", image:jenifer_nikcet_20, details:""},



      






 
  ];
  
  export default data_photos;
  