import React from 'react'
import data_potos from '../../../images/DataPhotos';
import PotosImg from '../PhotosImg/PhotosImg';
import Col from "react-bootstrap/Col";

import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-autoplay.css';
import 'lightgallery/css/lg-share.css';
// Plugins


// plugins
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgAutoplay from 'lightgallery/plugins/autoplay';
import lgShare from 'lightgallery/plugins/share';





const PhotosItems = () => {

    const onInit = () => {
        console.log('lightGallery has been initialized');
    };


    

  return (
    <React.Fragment>

<Col lg="12">

<LightGallery
                onInit={onInit}
                speed={500}
                plugins={[lgThumbnail, lgZoom, lgAutoplay, lgShare]}
                elementClassNames='list-container'
                licenseKey= "my license"
                download={false}
                closeOnTap={true}
                    >

{ data_potos.map((portfolio, i)=>{
            return <PotosImg key={i} id={portfolio.id} name={portfolio.name} image={portfolio.image} details={portfolio.details} />
        })}
      </LightGallery>
   
    </Col>



      
    </React.Fragment>
  )
}

export default PhotosItems
