import React from 'react'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AboutIcon01 from "../../images/our-services-icon-17.jpg";
import AboutIcon02 from "../../images/our-services-icon-18.jpg";
import AboutIcon03 from "../../images/our-services-icon-10.jpg";
import AboutIcon04 from "../../images/our-services-icon-06.jpg";
import AboutIcon05 from "../../images/our-services-icon-20.jpg";





import { BiSolidDownArrow } from "react-icons/bi";



const WPaTab = () => {
  return (
    <>
           <section className="py-4 custom_accordian">
        <Container>
          <Row className="justify-content-md-center">

        


            <Col md={6}>

              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon01}
                    alt="AboutIcon01"
                    className="img-fluid"
                  />
                </Col>

                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-3 p-lg-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">
                        Hospitality & Logistics Management

                        </h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          In the planning stage ,we take a detailed brief from you. Our team of experienced event personnel will then help to execute your event's detailed logistics management with precision & perfection.




                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon03}
                    alt="AboutIcon01"
                    className="img-fluid"
                  />
                </Col>

                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-3 p-lg-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Performing Artist Coordination & Management
</h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          We take a brief from you of all the artists enlisted to perform at your event. We help you coordinate and manage the hospitality of artists/performers, ensure their onsite requirements are taken care of, and coordinate the show flow, and timeline of their performances.


                         </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>



            </Col>

            <Col md={6}>

              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon02}
                    alt="AboutIcon02"
                    className="img-fluid"
                  />
                </Col>
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-3 p-lg-4  rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Event Plan & Flow</h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          Once the proposed event timeline is shared by you, we oversee and coordinate with all agencies involved for their timely delivery and keep you updated about the same.


                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon04}
                    alt="AboutIcon02"
                    className="img-fluid"
                  />
                </Col>
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-3 p-lg-4  rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">F & B Management</h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          We ensure the caterer fulfills the menu commitment. We manage food counters to avoid gatecrashers and ensure rotation of food . Throughout the wedding, our team is on its toes to serve your guests well.


                         </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

            </Col>

        


            <Col md={6}>

<Row className="mb-4">
  <Col className="col-2">
    <img
      src={AboutIcon05}
      alt="AboutIcon02"
      className="img-fluid"
    />
  </Col>
  <Col className="col-10">
    <div className="accordion">
      <div className="accordion-item border-0 p-3 p-lg-4  rounded-0">
        <div className="d-flex justify-content-between">
          <h5 className="mb-0">Onsite Event Execution</h5>
          <BiSolidDownArrow />
        </div>

        <div className="accordion-item-content ">
          <div className="mt-2">
            <hr />
            <p>
            We oversee all vendor activities to achieve a seamless event. We ensure your florists deliver and decorate on time; we oversee Safa tying, saree draping, make-up coordination; we supervise Dhol players, mehendi teams, welcome hostesses, valet security, bouncers - any service providers and manage them.


            </p>
          </div>
        </div>
      </div>
    </div>
  </Col>
</Row>



</Col>





          </Row>
        </Container>
      </section> 
    </>
  )
}

export default WPaTab
