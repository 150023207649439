import React from 'react'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import AboutIcon01 from "../../images/our-services-icon-01.jpg";
import AboutIcon02 from "../../images/our-services-icon-02.jpg";
import AboutIcon03 from "../../images/our-services-icon-03.jpg";
import AboutIcon04 from "../../images/our-services-icon-04.jpg";
import AboutIcon05 from "../../images/our-services-icon-05.jpg";
import AboutIcon07 from "../../images/our-services-icon-07.jpg";
import AboutIcon09 from "../../images/our-services-icon-09.jpg";
import AboutIcon11 from "../../images/our-services-icon-11.jpg";
import AboutIcon12 from "../../images/our-services-icon-12.jpg";
import AboutIcon13 from "../../images/our-services-icon-13.jpg";
import AboutIcon14 from "../../images/our-services-icon-14.jpg";
import AboutIcon15 from "../../images/our-services-icon-15.jpg";
import AboutIcon16 from "../../images/our-services-icon-16.jpg";
import AboutIcon17 from "../../images/wedding-menu-icon.jpg";
import AboutIcon18 from "../../images/photography-icon.jpg";
import AboutIcon19 from "../../images/choreography-icon.jpg";
import { BiSolidDownArrow } from "react-icons/bi";
import '../WeddingPlanning/WeddingPlanning.css';



const WPtab = () => {

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
           <section className="py-4 custom_accordian">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={6}>
              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon01}
                    alt="AboutIcon01"
                    className="img-fluid"
                  />
                </Col>

                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 rounded-0 p-3 p-lg-4">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">
                        Personalized Wedding Planning
                        </h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          Our motto is - Planning with personality. There's no way we will proceed any further than you want us to. We plan weddings according to your taste and personality. We give you a complimentary consultation to understand your vision for the wedding. We can plan and execute theme weddings, <Link to="/Destination-Wedding" onClick={scrollToTop} className="text-danger">
                          destination weddings
                      </Link> , simple weddings within your budget.
                          

                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>


              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon03}
                    alt="AboutIcon01"
                    className="img-fluid"
                  />
                </Col>

                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0  rounded-0 p-3 p-lg-4">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Vendor Management
                        </h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          Vendors can be quite the hassle , but don't stress and watch us tackle. From vendor referrals to attending vendor meetings, coordinating, managing and overseeing the execution of vendor services, we have an expertise in it all. We ensure that all deliverables by vendors meet committed standards of price and quality. Our main aim is to ensure that our client enjoys their event to the fullest, leaving behind all the planning, coordination, execution on us.


                         </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>



              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon05}
                    alt="AboutIcon01"
                    className="img-fluid"
                  />
                </Col>

                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 rounded-0 p-3 p-lg-4">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">
                        Wedding Collaterals
                        </h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          We help in creating a visual brand identity for the couple such as wedding websites, invitation cards, personalized e-invites, car stickers, welcome banner, video invites, easel stands. Suggesting different designs, vendors helping in its execution to curating the perfect personalized wedding favors, we ensure our clients requirements and dreams are fulfilled. For us creating a wedding brand for the couple equals creating a wedding as unique as "YOU".



                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>


              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon07}
                    alt="AboutIcon01"
                    className="img-fluid"
                  />
                </Col>

                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 rounded-0  p-3 p-lg-4">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">
                        Design & Decor
                        </h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          What is the fad now is passé tomorrow. If the venue has a monopoly we help you get the best from what they have else we can get our own vendors to do the best for you. And honestly, we are not decorators but definitely have the expertise in conceptualizing, designing and coordinating all aspects of wedding design and decor, including lighting, floral set-ups and execution of themes, after understanding your vision.


                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon09}
                    alt="AboutIcon01"
                    className="img-fluid"
                  />
                </Col>

                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 rounded-0  p-3 p-lg-4">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">
                        Wedding Entertainment
                       </h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          The key ingredient to the success of any wedding lies in its entertainment. We help you achieve this by offering a plethora of entertainment options -live bands , celebrity performers, anchors, live acts, laser shows, LED screens, LED dance floor, light and sound shows, SFX effects, pyro-technology, backed by the necessary audio visual support, lighting design and special effects.


                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon11}
                    alt="AboutIcon01"
                    className="img-fluid"
                  />
                </Col>

                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 rounded-0  p-3 p-lg-4">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">
                        Wedding Hampers
                       </h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          We help curate the most beautiful and exclusive personalized hampers for all occasions such as welcome hampers ,mehndi ceremony gifts, return favors etc and if you so desire with a beautifully penned poem or thank you note personalized for the occasion. We also undertake elegant trousseau packaging.


                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>


              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon13}
                    alt="AboutIcon01"
                    className="img-fluid"
                  />
                </Col>

                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 rounded-0 p-3 p-lg-4">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">
                        Permissions and Licences
                       </h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          With our years of experience we help you in being aware of requisite licences and permissions and if so desired procure the same on your behalf. Many clients are not aware of these wedding licenses that are required and hence our experienced team takes care of it all.


                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon15}
                    alt="AboutIcon01"
                    className="img-fluid"
                  />
                </Col>

                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 rounded-0 p-3 p-lg-4">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">
                        Event Timeline
                       </h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          We curate a flexible timeline for all the wedding events, unriot the chaos, tick detailed checklists, chalk detailed itineraries, and give you a chance to enjoy the D- Day stress-free and wholeheartedly. An event timeline when planned in detail can make your work easy. From planning and ideating over a timeline months before the wedding, to making an extensive guest list and scheduling various wedding related appointments- our experienced team will ensure the clients wedding schedule is planned smoothly.
                          </p>

                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

            </Col>

            <Col md={6}>
              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon02}
                    alt="AboutIcon02"
                    className="img-fluid"
                  />
                </Col>
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 rounded-0 p-3 p-lg-4">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Wedding Venue & Location</h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          We help in tackling the delicate issue of selecting the ideal wedding venue and location based on your budget, functions & requirements. We get you comparatives and get the best possible deals. We haggle like it's our own wedding to give you the ideal, perfect wedding destination of your dreams.


                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>



              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon04}
                    alt="AboutIcon02"
                    className="img-fluid"
                  />
                </Col>
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 rounded-0 p-3 p-lg-4">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Budget Management</h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          Weddings are a celebration of love. It's not about spending the least amount of money or the most amount of money. We understand, prepare your wedding budget, which covers all expenses and ensure you spend money on the elements that are most important to you and family.


                         </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>


              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon17}
                    alt="AboutIcon02"
                    className="img-fluid"
                  />
                </Col>
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Wedding Menu</h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          When planning an unforgettable wedding, what people remember is if they enjoyed what they ate. We attend menu meetings and offer suggestions on F & B consulting, coordination, management and ensure the caterer fulfills the menu commitment.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon18}
                    alt="AboutIcon08"
                    className="img-fluid"
                  />
                </Col>
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 rounded-0 p-3 p-lg-4">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Wedding Photography, Videography, Cinematography
</h5>

                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          Our experienced team suggests and helps you choose from a list of the best wedding photographers and videographers for your event. From scheduling appointments with wedding photographers, selecting the one. which fits in your wedding budget to suggesting venues for bridal photoshoots, couple photoshoots, pre-wedding shoots and different poses, our team takes care of it all.




                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>




              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon19}
                    alt="AboutIcon02"
                    className="img-fluid"
                  />
                </Col>
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 rounded-0 p-3 p-lg-4">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Wedding Choreography</h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          Let your family dance performances stand out and shine. We suggest and help you choose choreographers for your solo, couple and family dance performances for various wedding functions.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>



              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon12}
                    alt="AboutIcon12"
                    className="img-fluid"
                  />
                </Col>
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-3 p-lg-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Wedding Logistics and RSVP</h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                       
                          No matter the mode of transport our experienced logistics team helps to take care of all logistics for your guests. Our in-house team follows up for RSVP and arranges for documents for easy check-in, coordinates on time of arrival, to facilitate pick up and travel. We go the extra mile to ensure you don't pay for extra miles.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon14}
                    alt="AboutIcon02"
                    className="img-fluid"
                  />
                </Col>
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-3 p-lg-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Couple Grand Entry</h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          Arrive in style. Make a stunning & memorable grand entry. Choose from an array of options as per your venue, budget and vision. We also provide suggestions about different themed couple entries along with lighting and music to enter with a bang. Another unique option we offer is a grand bridal entry and bride groom baaraat.


                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon16}
                    alt="AboutIcon02"
                    className="img-fluid"
                  />
                </Col>
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-3 p-lg-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Small Details</h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          Florists, home decor, wedding safas, wedding band, makeup and hair stylist, saree drapers, the list of service providers in a wedding is endless. You have the option to choose from our network of trusted service providers or we coordinate with the team chosen by you.


                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>







            </Col>
          </Row>
        </Container>
      </section> 
    </>
  )
}

export default WPtab
