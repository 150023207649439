import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GrPhone } from "react-icons/gr";
import Card from "react-bootstrap/Card";


import JigneshSheth from "../../images/Jignesh-sheth.jpg";
import Rajitha from "../../images/Rajitha-Khan.jpg";

const WhoWeAre = () => {
  return (
    <React.Fragment>
      <section className="inner_banner">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="position-absolute top-50 start-50 translate-middle">
                <h4 className="d-block text-center">About Us</h4>
                <h2 className="text-uppercase">Who We Are</h2>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="mb-lg-5 mb-4">
        <Container>
          <Row>
            <Col lg={6} md={12}>
              <div className="d-flex flex-row align-items-center">
                <div className="mb-2">
                  <img src={JigneshSheth} alt="" className="res-img"/>
                </div>
                <div className="ms-4 about-details">
                  <h3 className="text-danger fw-bold">Jineshh Vaidya</h3>
                  <span className="mb-1 d-block">Founder & CEO</span>
                  <span><GrPhone /> +91 9820332999</span>
                </div>
              </div>

            </Col>


            <Col lg={6} md={12} >
              <div className="d-flex flex-row align-items-center">
                <div>
                  <img src={Rajitha} alt="" className="res-img"/>
                </div>
                <div className="ms-4">
                  <h3 className="text-danger fw-bold">Rajitha Vasudevan Khan </h3>
                  <span className="mb-1 d-block">Co-Founder | Business Development | <br/> Client Servicing</span>
                  <span><GrPhone /> +91 9833743015</span>
                </div>
              </div>
            </Col>
          </Row>











        </Container>
      </section>


      <section>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="hlello mb-4 mb-lg-5 d-flex flex-column justify-content-center align-items-center">
                <h2 className="text-center">
                Meet the Team at Wedding Stories 
                  <span className="fw-bold text-center d-block">
                  We're M.A.D.!
                  </span>
                </h2>
                <h5 className="text-center">We're not just mad about events,
                  We are M.A.D. in the way we approach them!
                </h5>
              </div>
            </Col>
          </Row>
        </Container>
      </section>


<section>
 <Container>
<Row className="equal-height-row">

   <Col className="equal-height-col" md={12} lg={4} >
    <Card className="mb-4 text-center rounded-0">
      <Card.Body>
      <div className="bg-white p-3 rounded-3 min-height">
        <Card.Title><span className="pink_color">M</span>  For Mental Agility</Card.Title>
        <Card.Text className=" text-center">
        Our team thrives on Mental Agility. We pivot and adapt seamlessly, embracing the unpredictable nature of the events industry a wellspring of creativity.
        </Card.Text>
        </div>
      </Card.Body>
    </Card>
    </Col>


    <Col className="equal-height-col" md={12} lg={4}>
        <Card className="mb-4 text-center ">
      <Card.Body>
      <div className="bg-white p-3 rounded-3 min-height">

        <Card.Title><span className="pink_color">A</span>  For Analytical Approach</Card.Title>
        <Card.Text className=" text-center">
        With an Analytical Approach, we leave no detail unexamined. We dissect possibilities and reconstruct strategies with meticulous precision.
        </Card.Text>
    </div>
      </Card.Body>
    </Card>
      </Col>



      <Col className="equal-height-col" md={12} lg={4}>
    <Card className="mb-4 text-center">
      <Card.Body>
      <div className="bg-white p-3 rounded-3 min-height">
        <Card.Title> <span className="pink_color">D</span> For Deep Learning</Card.Title>
        <Card.Text className=" text-center">
        What sets us apart? Our capacity as Deep Learners. Every event is a lesson, and feedback is a golden nugget of knowledge. We internalize, iterate, and innovate, continuously elevating our game.
        </Card.Text>
        </div>
      </Card.Body>
    </Card>
      </Col>




</Row>
 </Container>
</section>









    </React.Fragment>
  );
};

export default WhoWeAre;
