import React from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import "../HomeCard/HomeCard.css";

const HomeCard = (props) => {
  return (
    <>
      <Col>
        <Card className="mb-4 ">
          <Card.Img variant="top" src={props.bannerimg} />
          <Card.Body className=" text-center">
            <div className="position-relative mb-4">
              <div className="position-absolute top-0 start-50 translate-middle">
                <div className=" icon_box">
                  <span className="wp-icon">
                  <img src={props.icon} alt="" />
                  </span>
                </div>
              </div>
            </div>

            <Card.Title>{props.title}</Card.Title>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default HomeCard;
