import React from 'react'
import './PhotosImg.css'
const PhotosImg = (props) => {
  return (

    <React.Fragment>
<a href={props.image} className='vid-list' title={props.name}  >
<img src={props.image} className="thumbnail" alt="img01" />


{/* <div className="flex-div">
    <div className="vid-info">
     <p className='underline'>{props.name}</p> 
    </div></div>  */}

</a>






</React.Fragment>


  )
}

export default PhotosImg
