import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from './NavbarComp';
import whatsappIcon from "../src/images/whatsapp_icon.svg";



function App() {
  return (
    <>
  <NavbarComp />
    <div className='whatsapp_icon'>
        <a href="https://wa.me/919833743015">
      <img src={whatsappIcon} alt='icon' />
      </a>
    </div>

    </>
  );
}

export default App;
