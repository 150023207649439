import React from 'react'
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";


const Testimonial = (props) => {
  return (
    <React.Fragment>
       <Col>
        <Card className="mb-4 rounded-0 border-0">
        <Card.Img variant="top" src={props.bannerimg} className='rounded-0 border border-5' /> 
        {/* <Card.Title className='mt-2 text-center small'>{props.title}</Card.Title> */}
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default Testimonial
