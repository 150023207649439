import React from 'react'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


import AboutIcon01 from "../../images/logo_story-tab-icon-01.jpg";
import AboutIcon02 from "../../images/logo_story-tab-icon-02.jpg";
import AboutIcon03 from "../../images/logo_story-tab-icon-03.jpg";
import AboutIcon04 from "../../images/logo_story-tab-icon-04.jpg";
import AboutIcon05 from "../../images/logo_story-tab-icon-05.jpg";



import { BiSolidDownArrow } from "react-icons/bi";



const Tab = () => {
  return (
    <>
           <section className="py-4 custom_accordian">
        <Container>
          <Row className="justify-content-md-center">
       
         
         
            <Col md={6}>
              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon01}
                    alt="AboutIcon01"
                    className="img-fluid"
                  />
                </Col>

                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">
                        The Central Red Heart: The love

                        </h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          Red represents many things. But for us, it stands for love. Love is the foundation of any marriage. However, we also want to sprinkle love during your wedding. We make sure the element of love shines throughout your wedding ceremonies.



                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon03}
                    alt="AboutIcon01"
                    className="img-fluid"
                  />
                </Col>

                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Mehendi ( Dark Green and Orange )
                        </h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          The Mehendi Ceremony is yet another auspicious occasion where both the bride and the groom adorn their hands with henna. The green henna turns dark brown and then becomes orange in a few days, thus we have included this colour palette in our logo.



                         </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md={6}>

              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon02}
                    alt="AboutIcon02"
                    className="img-fluid"
                  />
                </Col>
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Haldi (Yellow)</h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          The yellow heart is a beautiful reminder of a bride's preparation for the wedding. The Haldi Ceremony is where she is adorned with the ancient herb of cleansing and immunity-turmeric. This ancient herb contributes in creating a radiant look of the bride, hence this ceremony is so auspicious for us.


                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col className="col-2">
                  <img
                    src={AboutIcon04}
                    alt="AboutIcon02"
                    className="img-fluid"
                  />
                </Col>
                <Col className="col-10">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Sangeet ( Pink, Purple, Light Green )
</h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p>
                          Wedding Stories specializes in Wedding Entertainment. We try to infuse colours of beauty and aesthetics (Pink); of music (Purple) and of fun (Light Green) into the Sangeet, making it the most awaited function of your wedding.


                         </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>


            </Col>

        
            <Col md={6}>

              <Row className="mb-4 ">
                <Col className="col-3 ">
                  <img
                    src={AboutIcon05}
                    alt="AboutIcon05"
                    className="img-fluid w-100"
                  />
                </Col>
                <Col className="col-9">
                  <div className="accordion">
                    <div className="accordion-item border-0 p-4 rounded-0">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0">Bidai (Light brown)
</h5>
                        <BiSolidDownArrow />
                      </div>

                      <div className="accordion-item-content ">
                        <div className="mt-2">
                          <hr />
                          <p className='mb-2'>
                          This is a solemn occasion. This is where the wedding concludes and the marriage begins. For us, it's an auspicious ceremony where we get to run smaller errands for both the families so they unite and part with love and blessings.
                          </p>
                          <p>
                          The brand logo is our way of telling you that we want to know you up, close and personal before planning your wedding. Allow Wedding Stories to tell your wedding story your way.
                          </p>


                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

     


            </Col>






          </Row>
        </Container>
      </section> 
    </>
  )
}

export default Tab
