import React, { useState } from "react";
import Header from './Components/Header/Header';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import './NavbarComp.css';
import Logo from "././images/logo.png";
import {  Routes, Route, Link,  } from "react-router-dom";
import Home from './Components/Home/Home';
import About from './Components/About/About';
import Footer from './Components/Footer/Footer';
import Blogs from './Components/Resources/Blogs/Blogs';
import Faqs from './Components/Resources/Faqs/Faqs';
import Gallery from './Components/Gallery/Gallery';
import OtherEvents from './Components/OtherEvents/OtherEvents';
import WeddingEntertainment from './Components/WeddingEntertainment/WeddingEntertainment';
import WeddingPlanning from './Components/WeddingPlanning/WeddingPlanning';
import WeddingStories from './Components/WeddingStories/WeddingStories';
import ContactUs from './Components/ContactUs/ContactUs';
import Testimonials from './Components/Testimomnials/Testimonials';
import WhatWeDo from "./Components/About/WhatWeDo";
import WhoWeAre from "./Components/About/WhoWeAre";
import OurLogoStory from "./Components/About/OurLogoStory";
import WhyUs from './Components/WeddingPlanning/WhyUs';
import DestinationWedding from "./Components/DestinationWedding/DestinationWedding";
import MilestoneCelebration from "./Components/MilestoneCelebration/MilestoneCelebration";
import Career from './Components/Resources/Career/Career'
import CommunityEvents from "./Components/OtherEvents/CommunityEvents/CommunityEvents";
import CorporateEvents from "./Components/OtherEvents/CorporateEvents/CorporateEvents";
import { FaHome } from "react-icons/fa";
import MobileLogo from "././images/mobile-logo.png";
import Sitemap from "./Components/Sitemap/Sitemap";
import { useLocation } from "react-router-dom"; // Import useLocation and Link
import Photos from "./Components/Gallery/Photos/Photos";
import Videos from "./Components/Gallery/Videos/Videos";



const NavbarComp = (props) => {

const location = useLocation();  // Get the current location
const isParentActive = location.pathname.startsWith('/About');
const isParentActiveWP = location.pathname.startsWith('/WeddingPlanning');
const isParentActiveWE = location.pathname.startsWith('/Wedding-Entertainment');
const isParentActiveDW = location.pathname.startsWith('/Destination-Wedding');
const isParentActiveMC = location.pathname.startsWith('/Milestone-Celebration');
const isParentActiveOE = location.pathname.startsWith('/OtherEvents');
const isParentActiveResources = location.pathname.startsWith('/Resources');
const isParentActiveCpntact = location.pathname.startsWith('/Contact-Us');
const isParentActiveTestimonials = location.pathname.startsWith('/Testimonials');
const isParentActivePV = location.pathname.startsWith('/Gallery');




  const [show, setShow] = useState(false);
  const [sshow, setsShow] = useState(false);
  const [rshow, setrShow] = useState(false);
  const [oehow, setoeShow] = useState(false);
  const [pvhow, setpvShow] = useState(false);
  
  
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" })
    };

    
  return (
    <React.Fragment>
 
    <Header/>
{['xl'].map((expand) => (

        <Navbar  collapseOnSelect expand="lg" className="nav_bar_bg sticky-top" key="navigation">
 
          <Container fluid className='px-3 px-lg-3'>
          <Navbar.Brand  as={Link} to="/" className='d-lg-none'>
          <img src={MobileLogo} alt="Wedding Stories"/>
          </Navbar.Brand>

            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                Our Menu
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className=" mx-auto justify-content-between">
                <Nav.Link as={Link} to="/" activeclassname="" eventKey="/" onClick={scrollToTop}
                ><FaHome />
                </Nav.Link>
               

              <NavDropdown title="About Us" id="basic-nav-dropdown"
              activeclassname="active"
                show={show}
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
                active={isParentActive} // Highlight dropdown if any child is active
                className={isParentActive ? 'active' : ''}
                >
                    <NavDropdown.Item 
                    as={Link} 
                    to="/About/What-We-Do" 
                    eventKey="/About/What-We-Do" 
                    active={location.pathname === '/About/What-We-Do'}
                    onClick={scrollToTop }
                    >
                      What We Do</NavDropdown.Item>

                    <NavDropdown.Item 
                    as={Link} 
                    to="/About/Who-We-Are" 
                    activeclassname="active" 
                    eventKey="/About/Who-We-Are" 
                    active={location.pathname === '/About/Who-We-Are'}
                    onClick={scrollToTop}
                    >
                      Who We Are
                      </NavDropdown.Item>

                    <NavDropdown.Item  
                    as={Link} to="/About/Our-Logo-Story" 
                    activeclassname="active" 
                    eventKey="/About/Our-Logo-Story" 
                    onClick={scrollToTop}
                    active={location.pathname === '/About/Our-Logo-Story'}
                    >Our Logo Story</NavDropdown.Item>
                </NavDropdown>


           




                <NavDropdown title="Wedding Planning" id="basic-nav-dropdown1"
              activeclassname="active"
                show={sshow}
                onMouseEnter={() => setsShow(true)}
                onMouseLeave={() => setsShow(false)}
                active={isParentActiveWP}
                className={isParentActiveWP ? 'active' : ''}

                >
              <NavDropdown.Item as={Link} to="/WeddingPlanning/Wedding-Planning" eventKey="/WeddingPlanning/Wedding-Planning" onClick={scrollToTop} active={location.pathname === '/WeddingPlanning/Wedding-Planning'}>Our Services</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/WeddingPlanning/Why-Us" activeclassname="active" eventKey="/WeddingPlanning/Why-Us" onClick={scrollToTop}>Why-Us</NavDropdown.Item>
                </NavDropdown>
                    
                <Nav.Link 
                as={Link} 
                to="/Wedding-Entertainment" 
                activeclassname="active"
                eventKey="/Wedding-Entertainment" 
                onClick={scrollToTop}
                active={isParentActiveWE}
   
                >
                Wedding Entertainment
                </Nav.Link>

                <Nav.Link 
                as={Link} 
                to="/Destination-Wedding" 
                eventKey="/Destination-Wedding" 
                onClick={scrollToTop}
                active={isParentActiveDW
                }

                >
                  Destination Wedding
                  </Nav.Link>



                <Navbar.Brand  as={Link} to="/"  className=' d-none d-lg-block position-relative me-0'>
                    <img src={Logo} alt="Wedding Stories"/>
                  </Navbar.Brand>


                  <Nav.Link as={Link} to="/Milestone-Celebration" activeclassname="active" eventKey="/Milestone-Celebration" onClick={scrollToTop}   active={isParentActiveMC}>Milestone Celebrations</Nav.Link>


      <NavDropdown title="Other Events" id="basic-nav-dropdown2"
              activeclassname="active"
                show={oehow}
                onMouseEnter={() => setoeShow(true)}
                onMouseLeave={() => setoeShow(false)}
                active={isParentActiveOE}
                className={isParentActiveOE ? 'active' : ''}
                
                >
                    <NavDropdown.Item 
                    as={Link} 
                    to="/OtherEvents/Community-Events" 
                    activeclassname="active" 
                    eventKey="/OtherEvents/Community-Events" 
                    onClick={scrollToTop}
                    active={location.pathname === '/OtherEvents/Community-Events'}
                    >
                      Community Events</NavDropdown.Item>
                    <NavDropdown.Item 
                    as={Link} to="/OtherEvents/Corporate-Events" 
                    activeclassname="active" 
                    eventKey="/OtherEvents/Corporate-Events" 
                    onClick={scrollToTop}
                    active={location.pathname === '/OtherEvents/Corporate-Events'}

                    >
                      Corporate Events</NavDropdown.Item>
                </NavDropdown>



                <NavDropdown title="Gallery" id="basic-nav-dropdown3"
              activeclassname="active"
                show={pvhow}
                onMouseEnter={() => setpvShow(true)}
                onMouseLeave={() => setpvShow(false)}
                active={isParentActivePV}
                className={isParentActivePV ? 'active' : ''}
                
                >
                    <NavDropdown.Item 
                    as={Link} 
                    to="/Gallery/Photos" 
                    activeclassname="active" 
                    eventKey="/Gallery/Photos" 
                    onClick={scrollToTop}
                    active={location.pathname === '/Gallery/Photos'}
                    >
                      Photos</NavDropdown.Item>

                    <NavDropdown.Item 
                    as={Link} to="/Gallery/Videos" 
                    eventKey="//Gallery/Videos" 
                    onClick={scrollToTop}
                    active={location.pathname === '/Gallery/Videos'}

                    >
                      Videos</NavDropdown.Item>
                </NavDropdown>






                {/* <Nav.Link as={Link} to="/Gallery" activeclassname="active" eventKey="/Gallery" onClick={scrollToTop}>Gallery</Nav.Link> */}
                <Nav.Link as={Link} to="/Testimonials" activeclassname="active" eventKey="/Testimonials" onClick={scrollToTop} active={isParentActiveTestimonials}>Testimonials</Nav.Link>
                  <NavDropdown title="Resources" id="basic-nav-dropdown"
              activeclassname="active"
                show={rshow}
                onMouseEnter={() => setrShow(true)}
                onMouseLeave={() => setrShow(false)}
                active={isParentActiveResources} 
                className={isParentActiveResources ? 'active' : ''}
                >

                    <NavDropdown.Item as={Link} to="/Resources/Faqs" activeclassname="active" eventKey="/Resources/Faqs" onClick={scrollToTop} data-bs-dismiss="offcanvas" active={location.pathname === '/Resources/Faqs'}>FAQ's</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/Resources/Blogs" activeclassname="active" eventKey="/Resources/Blogs" onClick={scrollToTop}  active={location.pathname === '/Resources/Blogs'}>Blog</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/Resources/Career" activeclassname="active" eventKey="/Resources/Career" onClick={scrollToTop}  active={location.pathname === '/Resources/Career'}>Career</NavDropdown.Item>
                </NavDropdown>

                <Nav.Link as={Link} to="/Contact-Us" activeclassname="active" eventKey="/Contact-Us" onClick={scrollToTop}
                active={isParentActiveCpntact}
                >Contact</Nav.Link>
                  
        
                </Nav>
             
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}




                <Routes>
                <Route path="/" element={<Home />} ></Route>
                <Route path="/Home" exact element={<Home />} ></Route>
                <Route path="/About" exact element={<About />} ></Route>
                <Route path="/About/What-We-Do" element={<WhatWeDo />} ></Route>
                <Route path="/About/Who-We-Are" element={<WhoWeAre />} ></Route>
                <Route path="/About/Our-Logo-Story" exact element={<OurLogoStory />} ></Route>
                <Route path="/Resources/Blogs" exact element={<Blogs />} ></Route>
                <Route path="/Resources/Faqs" exact element={<Faqs />} ></Route>
                <Route path="/Gallery" exact element={<Gallery />} ></Route>
                <Route path="/Gallery/Photos" exact element={<Photos />} ></Route>
                <Route path="/Gallery/Videos" exact element={<Videos />} ></Route>
                <Route path="/Other-Events" exact element={<OtherEvents />} ></Route>
                <Route path="/Wedding-Entertainment" exact element={<WeddingEntertainment />} ></Route>
                <Route path="/Destination-Wedding" exact element={<DestinationWedding />} ></Route>
                <Route path="/Milestone-Celebration" exact element={<MilestoneCelebration />} ></Route>
                <Route path="/OtherEvents/Community-Events" exact element={<CommunityEvents />} ></Route>
                <Route path="/OtherEvents/Corporate-Events" exact element={<CorporateEvents />} ></Route>
                <Route path="/Resources/Career" exact element={<Career />} ></Route>
                <Route path="/WeddingPlanning/Wedding-Planning" exact element={<WeddingPlanning />} ></Route>
                <Route path="/Wedding-Stories" exact element={<WeddingStories />} ></Route>
                <Route path="/Contact-Us" exact element={<ContactUs />} ></Route>
                <Route path="/Testimonials" exact element={<Testimonials />} ></Route>
                <Route path="/About/What-We-Do" element={<WhatWeDo />} ></Route>
                <Route path="/About/Who-We-Are" element={<WhoWeAre />} ></Route>
                <Route path="/About/Our-Logo-Story" exact element={<OurLogoStory />} ></Route>
                <Route path="/WeddingPlanning/Why-Us" exact element={<WhyUs />} ></Route>
                <Route path="/Sitemap" exact element={<Sitemap />} ></Route>


               </Routes>



  <Footer />


    </React.Fragment>
  )
}

export default NavbarComp
