import React from "react";
import "../DestinationWedding/DestinationWedding.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { NavLink } from "react-router-dom";
import Card from "react-bootstrap/Card";
import SWIIFOCImg from '../../images/SWIIFOCImg.jpg';
import DWAFICImg from '../../images/Destination-Weddings-Abroad-for-Indian-Couples.jpg'
import DestinationWeddingInIndia from '../../images/Destination-wedding-in-India.jpg'
import './DestinationWedding.css'

const DestinationWedding = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };




  return (
    <React.Fragment>
      <section className="inner_banner">
        <Container>
          <Row className="">
            <Col lg={12}>
              <div className="position-absolute top-50 start-50 translate-middle ">
                <h2 className="text-uppercase">Destination Wedding</h2>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className=" ">
        <Container className="pitch_bg">
          <Row>
            <Col lg="12 ">
              <div className="py-4 ps-3">
                <h2 className="text-center">
                  How We Make Destination Weddings Special
                </h2>
                <p className="text-center">
                  Destination Weddings are special. It is all about making
                  memories with your favourite people. It does not have to be
                  expensive. It just needs some smart planning. From ideation on
                  beautiful locations to transporting the guests to the chosen
                  venue safely and smoothly, ensuring guests are occupied with
                  various fun activities like carnival party, pool party ,
                  casino party, Bollywood night, Sufi night, we do it all. In
                  addition to pre-event site visits, we serve as a tool to
                  coordinate with the venue, vendors ensuring all arrangements
                  reach the venue on time.
                </p>

                <p className="text-center">
                  If you are someone who dreams of a destination wedding with a
                  handful of kith and kin or a destination wedding that hosts a
                  thousand people - we are ready to plan and host it for you.
                  Wherever around the world you plan to wed, we will transform
                  it into your own personal zen, your own personal love story.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="my-4">
        <Container>
          <Row>
            <Col lg="12 py-4">
              <div className="text-center">
              <h2 className="redColor">Guiding Your Destination Wedding Journey </h2>

              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row className="d-flex align-items-center " >
            <Col lg={6}>
              <img src={SWIIFOCImg} alt=""  className="img-fluid w-100"/>
            </Col>
            <Col  lg={6} >
           <h4 className="mt-2"> Destination Weddings in India for overseas couples </h4>
           <p>You may be foreign nationals living in any part of the world or Indians living abroad.  You have arrived at the right decision by choosing a Destination Wedding in India. India is full of amazing places that are perfect  for couples who dream of tying the knot in an exotic location. We have planned successful weddings at popular Indian locations such as Jaipur, Udaipur, the states of Goa and Kerala. These have included weddings organized on beaches, backwaters, regal ancient palaces, open air deserts, cruises among other places. </p>
             </Col>
          </Row>


      <Row className="d-flex align-items-center" >
        <Col lg={6} className="order-1 order-lg-0">
        <h4 className="mt-2">Destination Weddings Abroad for Indian couples </h4>
           <p> You can take us out of India but you cannot take India out of us. Our team is ready to travel to any part of the world to plan and organize an Indian wedding. We bring in the quintessential flavour of India to your wedding. And that’s a commitment. We have done destination weddings for Gujarati, Marwari, Punjabi families abroad, never missing a single beat of Indian traditions. Our savvy team gets into the groove of the wedding the moment we step into a place. We study your city or location, listen to your requirements, network with vendors or the best of local contractors to give you a wedding that you remember for a lifetime. </p>
             </Col>
             <Col lg={6}>
              <img src={DWAFICImg} alt=""  className="img-fluid w-100"/>
            </Col>
        </Row>


        <Row className="d-flex align-items-center " >
           
        <Col lg={6}>
              <img src={DestinationWeddingInIndia} alt=""  className="img-fluid w-100"/>
            </Col>
           <Col  lg={6} >
           <h4 className="mt-2">Destination wedding in India </h4>
           <p>You may be a Gujarati living in Bombay but wish to have a tropical wedding in Kerala backwaters or a Punjabi living in Delhi but dreaming of a royal wedding in a Palace in Jaipur - our team can plan and execute your destination wedding seamlessly. We have planned weddings in picturesque locales of Kerala, Goa, Rajasthan, Karnataka to name a few. </p>
             </Col>
        </Row>

        </Container>
      </section>



      <section className="my-5">
        <Container className="pitch_bg">
          <Row>
            <Col lg="12 ">
              <div className="py-4 ps-3">
                <h2 className="text-center">
                What does your wedding planning service include? 
                </h2>
                <p className="text-center">
                Confused about our service in wedding planning? <NavLink to="/WeddingPlanning/Wedding-Planning" onClick={scrollToTop}>
                <span className="fw-bold">Click here to know in detail</span> 
                      </NavLink>
                </p>

             
              </div>
            </Col>
          </Row>
        </Container>
      </section>

   





      <section>
 <Container>
<Row className="equal-height-row">
    <Col lg={12}>
    <div className="text-center mb-4">
                <h2>What makes us different?</h2>
              </div>
    </Col>


   <Col className="equal-height-col" md={12} lg={4} >
    <Card className="mb-4 text-center rounded-0">
      <Card.Body>
      <div className="bg-white p-3 rounded-3 min-height">
          <Card.Title>
            We understand the feel of the place before suggesting any theme. 
           </Card.Title>

        </div>
      </Card.Body>
    </Card>
    </Col>


    <Col className="equal-height-col" md={12} lg={4}>
        <Card className="mb-4 text-center ">
      <Card.Body>
      <div className="bg-white p-3 rounded-3 min-height">
          <Card.Title>
          We bring Indian wedding flavours anywhere in the world. 
        </Card.Title>

        </div>
      </Card.Body>
    </Card>
      </Col>



      <Col className="equal-height-col" md={12} lg={4}>
    <Card className="mb-4 text-center">
      <Card.Body>
      <div className="bg-white p-3 rounded-3 min-height">
          <Card.Title>
          We have a huge pan-India network of vendors to help us execute your wedding plan. 
         </Card.Title>

        </div>
      </Card.Body>
    </Card>
      </Col>

      <Col lg={12}>
    <div className="text-center mb-4 mt-3">
                <h2>Transform your destination wedding location into your personal zen.</h2>
              
              </div>
    </Col>


</Row>
 </Container>
</section>







    </React.Fragment>
  );
};

export default DestinationWedding;
