import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaFacebookF, FaLinkedin } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaPinterest } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';
import './ContactUs.css'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2'



const ContactUs = () => {


  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "99da9e3b-ebf2-4f56-bf52-f1e1cdb05ebb");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: json
    }).then((res) => res.json());

    if (res.success) {
      Swal.fire({
        title: "Sucess!",
        text: "Message sent successfully!",
        icon: "success",
     
      });
      event.target.reset();

    }
  };




  
  return (
    <React.Fragment>
  <section className="inner_banner ">
  <Container>
      <Row>
        <Col lg={12}>
        <div className="position-absolute top-50 start-50 translate-middle ">
            <h2 className="text-uppercase">GET IN TOUCH</h2>
             </div>
          </Col></Row>
          </Container> 
    </section>


    <Container className='mb-3  '>
      <Row>
        <Col lg={4}>

        <div className="bulletlist_contact ">
           <ul className=''>

           <li><h4 className='stylefont'><svg viewBox="0 0 34.62 34.62">
                                        <defs></defs>
                                        <g data-name="Layer 2">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <path  d="M26.22,34.62c-.6-.1-1.22-.19-1.82-.32a22.41,22.41,0,0,1-7.34-3.21A43.65,43.65,0,0,1,2.82,16.38,20.82,20.82,0,0,1,.25,9.89C.15,9.4.08,8.9,0,8.4V6.57c.09-.44.17-.89.27-1.34A3.94,3.94,0,0,1,1.49,3c.65-.57,1.23-1.22,1.85-1.83a4,4,0,0,1,5.89.23c1,1.23,2.1,2.47,3.11,3.73a3.91,3.91,0,0,1-.22,5.13c-.61.64-1.23,1.26-1.87,1.86A.7.7,0,0,0,10,13a16.37,16.37,0,0,0,1.1,2.22A30.78,30.78,0,0,0,17,21.66a17,17,0,0,0,3.89,2.66,5.1,5.1,0,0,0,1,.3.54.54,0,0,0,.56-.19c.57-.59,1.14-1.15,1.72-1.73a4,4,0,0,1,5.57-.25c1.12.93,2.25,1.87,3.36,2.82a4,4,0,0,1,.26,6.13c-.58.58-1.19,1.13-1.73,1.75a3.92,3.92,0,0,1-2.17,1.2c-.47.1-.95.18-1.42.27ZM23.64,25.5a1.9,1.9,0,0,0-.22.2,1.78,1.78,0,0,1-1.68.56,7.08,7.08,0,0,1-3.14-1.33,33.64,33.64,0,0,1-8.54-8.37,9.82,9.82,0,0,1-1.56-3A2.17,2.17,0,0,1,9.08,11a.11.11,0,0,0,0-.06L3.17,3.73A3.39,3.39,0,0,0,1.8,5.81,10.09,10.09,0,0,0,2,10.22,22.78,22.78,0,0,0,5,16.91a43,43,0,0,0,9.37,10.3,29.38,29.38,0,0,0,8.46,4.95,11.38,11.38,0,0,0,5.66.72,3.44,3.44,0,0,0,2.37-1.44ZM4.34,2.57l6,7.31.6-.67A2.32,2.32,0,0,0,11,6.13c-.37-.47-.76-.92-1.14-1.37-.66-.8-1.31-1.61-2-2.38A2.3,2.3,0,0,0,4.34,2.57ZM32.07,30.28a2.34,2.34,0,0,0,0-3.7c-1.15-1-2.31-1.94-3.47-2.91a2.46,2.46,0,0,0-3.76.7Z"></path>
                                                <path  d="M18.43,1.77A14.8,14.8,0,0,1,29.59,7.38a14.41,14.41,0,0,1,3.09,7c.11.73.13,1.47.17,2.22a.82.82,0,0,1-.79.91.84.84,0,0,1-.83-.91,12.93,12.93,0,0,0-1.86-6.67,13,13,0,0,0-9.2-6.34A18.91,18.91,0,0,0,18,3.4a.86.86,0,0,1-.92-.82A.82.82,0,0,1,18,1.77Z"></path>
                                                <path  d="M18.26,7.46a9.26,9.26,0,0,1,8.63,7,15.69,15.69,0,0,1,.27,2.17.81.81,0,0,1-.78.88.83.83,0,0,1-.84-.87,7.61,7.61,0,0,0-5.78-7.33A10,10,0,0,0,18,9.09c-.56-.05-.92-.34-.92-.82s.37-.81.95-.81Z"></path>
                                            </g>
                                        </g>
                                    </svg>Contact Us Directly</h4></li>
          <li className='mb-3'>
           <h5 className=''>Jineshh Vaidya</h5>
          +91 9820332999 
          </li>

          <li className='mb-3'>
           <h5 className=''>Rajitha Khan</h5>
            +91 9833743015 
          </li>


    <hr />
        
          <li>
            <h4 className='stylefont'>  <svg viewBox="0 0 45.36 34.05">
                                        <defs></defs>
                                        <g data-name="Layer 2">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <path  d="M0,3.19A12,12,0,0,1,.92,1.36,3.41,3.41,0,0,1,3.59,0H41.25a3.8,3.8,0,0,1,4.11,4.1V29.93A3.8,3.8,0,0,1,41.25,34c-12.35,0-24.69,0-37,0A4,4,0,0,1,.05,30.91s0-.05,0-.07ZM3.5,1.94l0,.13.22.14q8.76,8,17.54,16a1.93,1.93,0,0,0,3-.05L41.57,2.34A3,3,0,0,1,42,2.07l-.09-.13ZM18.33,18.18,3.39,32l.07.11H41.91L42,32,27,18.18l-1.57,1.41a3.81,3.81,0,0,1-5.58,0ZM2,3.27c-.19,1-.13,27,0,27.35L16.91,16.89ZM43.41,30.69V3.23c-5.06,4.62-10,9.16-15,13.66Z"></path>
                                            </g>
                                        </g>
                                    </svg>Email</h4></li>

            <li className='mb-3'>hello@weddingstories.co.in</li>



            <hr />

          <li><h4 className='stylefont'>
          <svg viewBox="0 0 28.47 34.54">
                                        <defs></defs>
                                        <g data-name="Layer 2">
                                            <g data-name="Layer 1">
                                                <path  d="M28.47,14A18.67,18.67,0,0,1,26,23.14,33.07,33.07,0,0,1,18.65,32c-.62.54-1.27,1.07-1.92,1.58a3.74,3.74,0,0,1-5,0,37.15,37.15,0,0,1-8-8.42A22.09,22.09,0,0,1,.33,17.44,14.25,14.25,0,0,1,28.09,11a13.94,13.94,0,0,1,.26,1.41C28.42,13,28.45,13.66,28.47,14ZM27,13.92c0-.43-.09-1.25-.23-2.05a12.74,12.74,0,0,0-25,4.75,19.23,19.23,0,0,0,3,7.38,34.94,34.94,0,0,0,8.17,8.55,2.15,2.15,0,0,0,2.76,0c1-.82,2-1.64,3-2.51a29.81,29.81,0,0,0,6-7.38A17.74,17.74,0,0,0,27,13.92Z"></path>
                                                <path  d="M14.27,21A6.75,6.75,0,1,1,21,14.14,6.74,6.74,0,0,1,14.27,21Zm5.22-6.73a5.23,5.23,0,1,0-5.25,5.23A5.21,5.21,0,0,0,19.49,14.25Z"></path>
                                            </g>
                                        </g>
                                    </svg>
            Address</h4></li>
          <li className='mb-3'>
          <h5 className=''>Wedding Stories</h5>
          1-B, 111, Varun Arcade, G.B. Service Road, Opposite R Mall, Thane West - 400610.
          </li>

             </ul>

            </div>
            <hr />
            <ul className="socialIcons text-left text-sm-left">
              <li> <a href="https://www.youtube.com/@weddingstoriesyt" target='_blank' rel='noreferrer' className=''><FaYoutube /></a></li>
              <li><a href="https://www.facebook.com/weddingstorieseventsandentertainment"  target='_blank' rel='noreferrer'><FaFacebookF /></a></li>
              <li><a href="https://www.instagram.com/weddingstoriesig" className=''  target='_blank' rel='noreferrer'> <FaInstagram /></a></li>
              <li><a href="https://in.pinterest.com/weddingstories"  target='_blank' rel='noreferrer'> <FaPinterest /></a></li>
              <li><a href="https://www.linkedin.com/company/wedding-stories-events-entertainment"  target='_blank' rel='noreferrer'> <FaLinkedin /></a></li>
                    

                    </ul>


          </Col>



          <Col lg={8}>
          
      <Form onSubmit={onSubmit} >

         <Form.Group className="mb-3" controlId="formBasicFullName">
        <Form.Label  className='fw-bold'>Full Name<span className='text-danger'>*</span> </Form.Label>
        <Form.Control name='name' type="text" placeholder="Enter full name"  required/>
         </Form.Group>

         <Form.Group className="mb-3" controlId="formBasicPartnersName" >
        <Form.Label className='fw-bold'>Partner's Full Name<span className='text-danger'>*</span></Form.Label>
        <Form.Control name='partnername' type="text" placeholder="Enter partner's Full Name"  required/>
         </Form.Group>


         <Form.Group className="mb-3" controlId="formBasicEmail" >
        <Form.Label  className='fw-bold'>Email address<span className='text-danger'>*</span></Form.Label>
        <Form.Control name='email' type="email" placeholder="Enter email"  required/>
         </Form.Group>


         <Form.Group className="mb-3" controlId="formBasicDOW" >
        <Form.Label  className='fw-bold'>Date of the Wedding<span className='text-danger'>*</span></Form.Label>
        <Form.Control name='date' type="date" placeholder="Enter date"  required/>
         </Form.Group>

         <Form.Group className="mb-3" controlId="formBasicGuest" >
        <Form.Label className='fw-bold'>Expected Number of Guests<span className='text-danger'>*</span></Form.Label>
        <Form.Control name='guest' type="number" placeholder="Enter expected number of guests"  required/>
         </Form.Group>

         <Form.Group className="mb-3" controlId="formBasicMessage" >
        <Form.Label  className='fw-bold'>Any other details you would like to share with us ? (optional)</Form.Label>
        <Form.Control  name='message'  as="textarea" rows={3} placeholder="Enter your message"/>
      </Form.Group>



      <Button type="submit" className='btn viewmore_btn  '>
        Send Message
      </Button>




    </Form>




          </Col>


          </Row>
          </Container> 


<Container fluid>
<Row>
<Col lg={12}>
<iframe title="myFrame" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7534.49841064657!2d72.976399!3d19.227968000000004!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b938558c0ed7%3A0xb16d1e9ac9803b6e!2sWedding%20Stories!5e0!3m2!1sen!2sin!4v1694252892171!5m2!1sen!2sin" 
          width="100%" 
          height="400" 
          style={{ border: "0" }}
          allowFullScreen="" 
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade"></iframe> 

</Col>
</Row>
</Container>



    </React.Fragment>

  )
}



export default ContactUs
